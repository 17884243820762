import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next';
import PaymentModal from '../PaymentModal';
import OwnershipModal from '../OwnershipModal';
import { useSelector, useDispatch } from 'react-redux';
import { retriveProductDataWithUPIDSAction } from 'src/redux/thunk/productThunk';


const DirectCommerce = (props) => {
    const { value, urlData, productData} = props
    const dispatch = useDispatch()
    const directCommerceData = value


    const _state = useSelector((state) => state.productSlice)
    const _commonState = useSelector((state) => state.commonSlice)
    const isOwner = (_commonState && _commonState.productOwner && _commonState.productOwner.owner) ? true : false;
    const { t, i18n } = useTranslation('common');
    const [isShowPaymentModal, setIsShowPaymentModal] = useState(false);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);

    const [state, setState] = useState({
        locale: "en",
        isLoading: false,
        data: productData,
        UPIDS: (urlData && urlData.UPIDS) ? urlData.UPIDS : '',
        isUpidsProduct: (urlData && urlData.isUpidsProduct) ? urlData.isUpidsProduct : false,
        upids_data: {}
    });

    const handleBuyNowClick = () => {
        setIsShowPaymentModal(!isShowPaymentModal);
    };

    const handleChangeOwnerClick = () => {
        setShowOwnershipModal(!showOwnershipModal);
    };

    useEffect(() => {
        getProductData()
    }, [])

    const getProductData = async () => {
        if (!!state.UPIDS && state.UPIDS != '') {
            const params = new URLSearchParams(window.location.search);
            const locale = params.get("lang");
            setState({ ...state, isLoading: true })

            if (state.isUpidsProduct) {
                try {
                    const upidsDataResponse = await dispatch(retriveProductDataWithUPIDSAction(state.UPIDS)).unwrap()
                    setState({ ...state, upids_data: upidsDataResponse })
                } catch (error) {
                    setState({ ...state, isLoading: false })
                }
            } else {
                // getDataWithGtin({ gtin: state.UPIDS })
            }
        }
    }

    return (
        <div>
            {directCommerceData && directCommerceData.deliveryType && directCommerceData.status && directCommerceData.shipping && directCommerceData.tax
                && directCommerceData.price && directCommerceData.price !== 0 &&
                <div>
                    {(directCommerceData.status !== "disabled" && !isOwner) ?
                        (
                            <div>
                                <div className="row mt-10">
                                    <div className="col-12">
                                        <span className="attributeTitle">{t("Direct commerce")}</span>
                                        {(directCommerceData.status === "sell" ||
                                            directCommerceData.status === "presale") &&
                                            t("This product is available for direct purchase")}
                                        {directCommerceData.status === "nostock" &&
                                            t("This product is out of stock")}
                                        {directCommerceData.status === "eol" && t("eol")}
                                        {directCommerceData.status === "na" && t("na")}
                                    </div>
                                </div>
                                {(directCommerceData.status === "sell" ||
                                    directCommerceData.status === "presale") && (
                                        <div className="row mt-10">
                                            <button
                                                type="button"
                                                onClick={() => handleBuyNowClick()}
                                                className="btn form-button cursor-pointer"
                                                style={{ background: "#cf2948", height: '3rem', borderRadius: "0.3125rem", width: "100%", color: "#ffffff", fontSize: 16 }}
                                            >
                                                {_.toNumber(directCommerceData.shipping) > 0 ? (
                                                    <>
                                                        {(_.toNumber(directCommerceData.price) + _.toNumber(directCommerceData.shipping)).toFixed(2)}{" "} {directCommerceData?.currencyType} {t("with shipping")} - {t("Buy now")}
                                                    </>
                                                ) : (
                                                    <>
                                                        {directCommerceData.price} {directCommerceData?.currencyType} - {t("Buy now")}
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    )}
                            </div>
                        ) :
                        <div className='attributeTitle mt-3 d-flex justify-content-center'>{t("You own this item")}</div>
                    }
                </div>}

            <PaymentModal
                productData={state}
                isShowModal={isShowPaymentModal}
                closeModal={handleBuyNowClick}
                directCommerceData={directCommerceData}
            />
            <OwnershipModal productData={state} isShowModal={showOwnershipModal} closeModal={handleChangeOwnerClick} />
        </div>
    )
}

export default DirectCommerce