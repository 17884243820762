export const nutritionalQuantityUnit = [
    {value: "Grams Per Cubic Centimetre", code: "23", symbol: 'g/cm³'},
    {value: "Kilocalorie", code: "E14", symbol: 'kcal'},
    {value: "Gram", code: "GRM", symbol: 'g'},
    {value: "Kilojoule", code: "KJO", symbol: 'kj'},
    {value: "Microgram", code: "MC", symbol: 'mcg'},
    {value: "Milligram", code: "MGM", symbol: 'mg'},
    {value: "Number of international units", code: "NIU", symbol: 'nu'},
    {value: "Percent", code: "P1", symbol: 'kcal'},
    {value: "Retinol Equivalent", code: "XRE", symbol: 're'}
  ]

  export const nutritionalNames= [
    {value:"Biotin", code:"BIOT"},
    {value:"Calsium", code:"CA"},
    {value:"Caffeine", code:"CAFFN"},
    {value:"Beta-carotene", code:"CARTB"},
    {value:"Carbohydrate", code:"CHOAVL"},
    {value:"Cholesterol", code:"CHOL-"},
    {value:"Chloride", code:"CLD"},
    {value:"Chromium", code:"CR"},
    {value:"Copper", code:"CU"},
    {value:"Energy", code:"ENER-"},
    {value:"Fatty acids, monounsaturad-cis", code:"FAMSCIS"},
    {value:"Fatty acids, polyunsaturated-cis", code:"FAPUCIS"},
    {value:"Fatty acids, total saturated", code:"FASAT"},
    {value:"Fat", code:"FAT"},
    {value:"Fluoride", code:"FD"},
    {value:"Iron", code:"FE"},
    {value:"Fibre", code:"FIBTG"},
    {value:"Folate", code:"FOL"},
    {value:"Folic acid", code:"FOLDFE"},
    {value:"Fructose", code:"FRUS"},
    {value:"Ginseng", code:"GINSENG"},
    {value:"Betaglucan", code:"GLUCNB"},
    {value:"Nicotine", code:"G_NICT"},
    {value:"Iodide", code:"ID"},
    {value:"Potassium", code:"K"},
    {value:"Lactose", code:"LACS"},
    {value:"Carnitine", code:"L_CARNITINE"},
    {value:"Magnesium", code:"MG"},
    {value:"Manganese", code:"MN"},
    {value:"Molybdenum", code:"MO"},
    {value:"Sodium", code:"NA"},
    {value:"Salt", code:"NACL"},
    {value:"Niacin", code:"NIA"},
    {value:"Beta-hydroxy-beta-methylbutyrate", code:"NUCLEOTIDE"},
    {value:"Phosphorus", code:"P"},
    {value:"Pantothenic acid", code:"PANTAC"},
    {value:"Polyols, total", code:"POLYL"},
    {value:"Protein", code:"PRO-"},
    {value:"Riboflavin", code:"RIBF"},
    {value:"Salt equivalent", code:"SALTEQ"},
    {value:"Selenium", code:"SE"},
    {value:"Starch, total", code:"STARCH"},
    {value:"Sugars, total", code:"SUGAR-"},
    {value:"Taurine", code:"TAU"},
    {value:"Thiamin", code:"THIA"},
    {value:"Vitamin A", code:"VITA-"},
    {value:"Vitamin B-12", code:"VITB12"},
    {value:"Vitamin B-6", code:"VITB6-"},
    {value:"Vitamin C", code:"VITC-"},
    {value:"Vitamin D", code:"VITD-"},
    {value:"Vitamin E", code:"VITE-"},
    {value:"Vitamin K", code:"VITK"},
    {value:"Combined saturated fat and trans fat", code:"X_FATRSA"},
    {value:"Zinc", code:"ZN"}
  ]

  export const toLocaleDateStringData =[
    {lang: "en", value:"en-GB" },
    {lang: "fi", value:"fi-FI" },
    {lang: "sv", value:"sv-SE" },
    {lang: "zh", value:"zh-HK" },
    {lang: "de", value:"de-DE" },
    
  ]