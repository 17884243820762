import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { changeProductOwner } from 'src/redux/thunk/commonThunk';
import { localStorageGet, Keys } from 'src/helper/localStorageHelper';
import { disableOwnership } from 'src/redux/slice/commonSlice';

/**
 * React component for Stripe card payments
 */
const Ownership = (props) => {
  const { productData, handleClose } = props;
  const { t } = useTranslation("common");

  const data = productData.upidsData;

  const CheckoutForm = () => {
    const dispatch = useDispatch();
    const [newOwner, setNewOwner] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [changeStatus, setChangeStatus] = useState("");

    useEffect(() => {
      if (!props.productData.commonState.changeProductOwner) {
        setChangeStatus("");
      } else if (props.productData.commonState.changeProductOwner && props.productData.commonState.changeProductOwner.status === "ok") {
        setChangeStatus("success");
      } else if (props.productData.commonState.changeProductOwner && props.productData.commonState.changeProductOwner.status === "error") {
        setChangeStatus("error");
      }
    }, [props.productData.commonState.changeProductOwner]);

    /**
     * Handler for form submit
     * Creates a payment intent and then confirms it
     */
    const handleSubmit = async () => {
      setIsLoading(true);

      const upidsId = data.upidsId;

      const email = localStorageGet(Keys.LOGIN_EMAIL);

      if (upidsId && email) {
        await dispatch(changeProductOwner({url: "/ownership/change", data: {current: email, new: newOwner, upidsid: upidsId}})).unwrap();
      }

      setIsLoading(false);
    };

    const handleSuccessOk = () => {
      dispatch(disableOwnership());
      handleClose();
    };

    return (
      <>
        {changeStatus === "" ? (
          <form id="ownership-form" onSubmit={handleSubmit}>
            <div>
              <div className="row">
                <div className="col-12">
                  <input
                    id="newOwnerEmail"
                    className="payment-input"
                    title={t("Email address of new owner")}
                    type={"email"}
                    placeholder={t("Email address of new owner")}
                    value={newOwner}
                    onChange={(e) => setNewOwner(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="margin-top-10">
                <button
                  type="button"
                  onClick={() => handleSubmit()}
                  className="btn form-button cursor-pointer"
                  style={{  background: "#cf2948", height: 40, color: "#ffffff",fontSize:16 }}
                >
                  {t("Change owner")}
                </button>
              </div>
            </div>
          </form>
        ):(
          <div>
            {changeStatus === "success" ? (
              <div>
                <div>{t("Ownership changed")}</div>
                <button
                type="button"
                onClick={handleSuccessOk}
                className="btn btn-primary form-button cursor-pointer"
              >
                {t("OK")}
              </button>

              </div>
            ):(
              <div>{t("Something went wrong")}</div>
            )}
          </div>
          )}
      </>
    );
  };

  return (
    <>
      <div className="upidsPayment">
        <CheckoutForm />
      </div>
    </>
  );
};

export default Ownership;
