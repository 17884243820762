import React, { useState } from 'react'
import { useSelector } from "react-redux";
import _ from 'lodash';
import Modal from "react-bootstrap/Modal";


function OverlayUrlModal() {

    const { productData } = useSelector((state) => state.productSlice)

    const [isShowModal, setIsShowModal] = useState(true)

    const handleShowModal = () => {
        setIsShowModal(false)
    }


    return (
        <>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
                backdrop="static"
                show={isShowModal && _.get(productData, `marketing.overlayUrl`)}
                onHide={handleShowModal}
                className="overlayUrlModal"
            // fullscreen={true}
            >
                <Modal.Header closeButton={handleShowModal}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal', height: "86vh", }}>
                    <div className="row m-0 justify-content-center " style={{ margin: "0px", padding: "0px", height: "100%" }}>
                        <iframe
                            className='overlayUrlModal__iframe'
                            id=""
                            width="100%"
                            height="100%"
                            src={_.get(productData, `marketing.overlayUrl`)}
                        /** 
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        // allowFullScreen
                        */
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OverlayUrlModal