/**
 * App Header
 */
import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import { localStorageGet, Keys, localStorageRemove, localStorageSave } from '../helper/localStorageHelper';
import { AppConfig } from '../helper/AppConfig';
/** import { ReactCountryFlag } from "react-country-flag"; */
import { handleChangLanguage } from "src/locales/languageHelper"
import Cookies from 'universal-cookie';
import { getUserProfileAction, requestLoginLinkAction, getPrivacyPolicyDataAction } from "src/redux/thunk/commonThunk";
import { AppContext } from "../AppContext";
import LoginModal from "./LoginModal";
import { HiOutlineLogin, HiOutlineLogout } from "react-icons/hi";
import { IconContext } from "react-icons"
import { Spinner } from "react-bootstrap";
import { StCloseButton } from "./StCloseButton";
import Modal from "react-bootstrap/Modal";
import { languageListForSelect } from "src/locales/i18n";


const Header = () => {
  const { t, i18n } = useTranslation('common');
  const dispatch = useDispatch();
  const _state = useSelector((state) => state.commonSlice.login, shallowEqual);

  const headerSelectLanguageRef = useRef(null);
  const headerBackgroundColorRef = useRef(null);
  const headerMenueColorRef = useRef(null);

  const productData = useSelector((state) => state.productSlice.productData)
  const isDataFromExternalSource = useSelector((state) => state.productSlice.isDataFromExternalSource)
  const context = useContext(AppContext);
  const userProfile = useSelector((state) => state.commonSlice.user);
  const { isLoading, emailResponse } = useSelector((state) => state.commonSlice);
  const cookies = new Cookies();
  const _authState = cookies.get('AUTH_STATE');
  const [showLoginModal, setShowLoginModal] = useState(false);

  const [state, setState] = useState({
    open: false,
    defaultLang: localStorageGet(Keys.I18LANG_CODE)
  })
  const [showNoticeToggle, setShowNoticeToggle] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [loggedInEmail, setLoggedInEmail] = useState()
  const [showModal, setShowModal] = useState(false);
  const [privacyAgree, setPrivacyAgree] = useState(false);
  const [privacyPolicyModalData, setPrivacyPolicyModalData] = useState({
    isShow: false,
    content: ""
  })


  useEffect(() => {
    const email = localStorageGet(Keys.LOGIN_EMAIL);
    setLoggedInEmail(email);
  }, [])

  useEffect(() => {
    if (emailResponse && emailResponse.message === "Ok") {
      const email = localStorageGet(Keys.LOGIN_EMAIL);
      cookies.set('CURRENT_PATH', window.location.href);
      setLoggedInEmail(email);
      setShowModal(true);
      toggleHeader();
    }
  }, [emailResponse]);

  useLayoutEffect(() => {
    if (_.get(productData, 'marketing.custom.bgColor')) {
      headerSelectLanguageRef.current.style.setProperty("background-color", `${productData.marketing.custom.bgColor}`, "important");
      headerBackgroundColorRef.current.style.setProperty("background-color", `${productData.marketing.custom.bgColor}`, "important");
      headerMenueColorRef.current.style.setProperty("background-color", `${productData.marketing.custom.bgColor}`, "important");
    }
  }, [productData])

  useEffect(() => {
    if (userProfile && userProfile.email) {
      alert(userProfile.email);
    }
  }, [userProfile]);

  const toggleHeader = () => {
    setState({ ...state, ['open']: !state.open })
  }

  const handleLangChange = (e) => {
    handleChangLanguage(e.target.value)
    setState({ ...state, defaultLang: e.target.value });
  }

  const getUserProfile = (e) => {
    dispatch(getUserProfileAction());
  }

  const logout = (e) => {
    cookies.remove("AUTH_STATE");
    localStorageRemove("AUTH_STATE");
  }

  const toggleShowLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const handleSendButton = () => {
    const data = {
      email: emailText,
      language: i18n.language && i18n.language
    };
    dispatch(requestLoginLinkAction(data));
    localStorageSave(
      Keys.LOGIN_EMAIL,
      emailText
    );
  }

  const handleLogoutButton = () => {
    cookies.remove("AUTH_STATE");
    localStorageRemove("AUTH_STATE");
    setLoggedInEmail();
  }

  const getPrivaryPlicyData = async () => {
    try {
      const responseData = await dispatch(getPrivacyPolicyDataAction()).unwrap()
      const privacy = _.get(responseData, "[0].content", "")
      if (privacy) {
        setPrivacyPolicyModalData({
          ...privacyPolicyModalData,
          isShow: true,
          content: privacy
        })
      }
    } catch (error) {

    }
  }
  console.log("flag is",!privacyAgree || emailText=="" ? true : false)

  return (
    <div className="header" style={{ marginBottom: 10 }}>
      <nav className="navbar bgSuccess " ref={headerBackgroundColorRef}>
        <div className="container-fluid">
          <div className="pageTitle left" style={{ width: '50%' }}>
            {!!productData &&
              <div style={{ fontSize: 20, color: '#ffffff' }}>
                {_.get(productData, 'multimedia.logo[0]') && (
                  <img
                    src={_.get(productData, 'multimedia.logo[0]')}
                    alt="UPIDS"
                    className="logo"
                  />
                )}


                {!state.open && _.get(productData, 'marketing.custom.headerText') && (
                  <>
                    {productData.marketing.custom.headerText}
                  </>
                )}

                {(!_.get(productData, 'multimedia.logo[0]') && !_.get(productData, 'marketing.custom.headerText')) &&
                  <img
                    src={require("src/assets/img/brand_images/upids-io-logo.png")}
                    alt="UPIDS"
                    className="logo"
                  />
                }
              </div>
            }
          </div>

          {/* <div>
          <IconContext.Provider value={{ color: "white", size: 28}}>
            {(!!_authState && context.authState) ?
              <div className="text-white">
                <span>{context.authState.email}</span>
                <span className="ml-10"><a href="#" onClick={() => logout()}><HiOutlineLogout /></a></span>
              </div>
              :
              <div>
                <a href="#" onClick={() => toggleShowLoginModal()}><HiOutlineLogin /></a>
              </div>
              }
            </IconContext.Provider>
          </div> */}

          <div className="right" style={{ zIndex: 99 }}>
            {/* <ReactCountryFlag style={{ margin: 8, fontSize: 30 }} countryCode={flagsData[state.defaultLang].countryCode} /> */}
            <select
              style={{ marginRight: 10 }}
              value={state.defaultLang} className="col-8 language-select" onChange={handleLangChange}
              ref={headerSelectLanguageRef}
            >
              {
                languageListForSelect && languageListForSelect[0] && languageListForSelect.map((item, index) => (
                  <option value={item.value} key={index}>{item.label}</option>
                ))
              }
            </select>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleHeader}
            >
              {!state.open ?
                <img
                  src={require("src/assets/img/Menu.svg").default}
                  alt="Toggle"
                  style={{ height: '24px', width: '24px' }}
                /> : <i
                  className="ti-close textPrimary fwbold" style={{ height: '24px', width: '24px' }}
                />}
            </button>
          </div>
        </div>

      </nav>

      {isDataFromExternalSource && <div style={{ backgroundColor: '#ff6347', color: '#ffffff' }}>
        <div className="row">
          <div className="col-11"><span className="noticeDiv">{t("Notice indicator")}</span></div>
          <div className="col-1" role={'button'} onClick={() => setShowNoticeToggle(!showNoticeToggle)}><i className={showNoticeToggle ? "bi-chevron-up" : "bi-chevron-down"} /></div>
          {showNoticeToggle && <div className="mt-1">
            <p className="noticeInfo">{t('Notice crowdsourced information')}</p>
          </div>}
        </div>
      </div>}

      <div className={!state.open ? "header-collapse" : "header-show"} id="" style={{ width: '100vw' }}>
        <div className="bgSuccess p-4" ref={headerMenueColorRef} >
          {/* <span className="textWhite fw18">
            {t('Unique Product Identification Service stores rich data about product lifecycle, end to end. Know what you eat, know what you consume. In atomic level. That is UPIDS.')}
          </span> */}
          {!!_authState ?
            <div >
              <div>
                <label className="login-lable">{t("You are logged in as")} <span style={{ fontSize: 18, fontWeight: 'bold', wordBreak: 'break-word' }}>{_authState.email}</span></label>
              </div>
              <button className="login-button" onClick={() => handleLogoutButton()}>
                {!isLoading ? t("Logout") : <Spinner animation="border" variant="light" />}
              </button>
            </div>
            :
            <div >
              <div>
                <label className="login-lable">{t("Login")}</label>
              </div>
              <input name="email" className={"login-input"} type="text" placeholder={t("Enter email address to receive login link")}
                onChange={(e) => setEmailText(e.target.value)} />

              <div className="row pt-10">
                <div className="col-12">
                  <div style={{ display: "inline-flex" }}>
                    <input
                      name="publish"
                      type="checkbox"
                      className="checkbox"
                      onChange={(e) => setPrivacyAgree(e.target.checked)}
                      checked={privacyAgree}
                    />
                    <a style={{ cursor: "pointer", color: "#FFFFFF", font: "1rem", textDecoration: "underline" }} onClick={getPrivaryPlicyData}>
                      {t("I have read privacy policy")}
                    </a>
                  </div>
                </div>
              </div>
              <button className="login-button"
                onClick={() => handleSendButton()}
                disabled={!privacyAgree || emailText=="" ? true : false}
              >
                {!isLoading ? t("Send") : <Spinner animation="border" variant="light" />}
              </button>
            </div>
          }
          <div className="mt-20">
            {productData?.marketing?.publicEmail &&
              <div className="row">
                <div className="col-4 textWhite fw16 fwbold">{t("Email Us")}</div>
                <div className="col-8">
                  <span className="textWhite fw16">{productData?.marketing?.publicEmail}</span>
                </div>
              </div>}
            {productData?.marketing?.publicTelephone &&
              <div className="row">
                <div className="col-4 textWhite fw16 fwbold">{t("Call Us")}</div>
                <div className="col-8">
                  <span className="textWhite fw16">{productData?.marketing?.publicTelephone}</span>
                </div>
              </div>}
          </div>

          <Modal size="lg" centered={true} show={privacyPolicyModalData.isShow}
            style={{ zIndex: 9999999999 }}
            onHide={() => setPrivacyPolicyModalData({
              ...privacyPolicyModalData,
              isShow: false
            })}
          >
            <Modal.Header>
              <div style={{ position: "absolute", right: 10 }}>
                <StCloseButton text={t("Close")} onClick={() => setPrivacyPolicyModalData({
                  ...privacyPolicyModalData,
                  isShow: false
                })} />
              </div>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "80vh", overflow: "auto" }}>
              <div dangerouslySetInnerHTML={{ __html: privacyPolicyModalData.content }} />
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center' }}>
              <button className="login-button"
                onClick={() => setPrivacyPolicyModalData({
                  ...privacyPolicyModalData,
                  isShow: false
                })}
              >
                {t("Ok")}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <Modal size="sm" centered={true} show={showModal} onHide={() => setShowModal(false)} >
        <Modal.Header>
          <div style={{ position: "absolute", right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => setShowModal(false)} ></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: 16 }}>{t('You should receive a login link')}</p>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <button className="login-button" onClick={() => setShowModal(false)}> {t("Ok")} </button>
        </Modal.Footer>
      </Modal>




      {/* <LoginModal showModal={showLoginModal} closeModal={toggleShowLoginModal} /> */}
    </div>
  );
};

export default Header;
