import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MdClose } from 'react-icons/md';

import msc from '../../assets/img/certifications/msc_certificate_logo.jpeg';
import utz from '../../assets/img/certifications/utz_certificate_logo.jpeg';
import rainforestalliance from '../../assets/img/certifications/rainforestAlliance_certificate_logo.jpeg';
import nutriscore_a from "../../assets/nutriscore/nutriscore-a.svg"
import nutriscore_b from "../../assets/nutriscore/nutriscore-b.svg"
import nutriscore_c from "../../assets/nutriscore/nutriscore-c.svg"
import nutriscore_d from "../../assets/nutriscore/nutriscore-d.svg"
import nutriscore_e from "../../assets/nutriscore/nutriscore-e.svg"
import { useDispatch, useSelector } from "react-redux";
import fin from "../../assets/flag/fin.svg"
import { getAllCertificationsData } from "src/redux/thunk/productThunk";



const CertificationsLogo = (props) => {
    const dispatch = useDispatch()
    const { value } = props

    const [allCertifications, setAllCertifications] = useState([])
    const commonMetaDataState = useSelector((state)=>state?.productSlice?.commonMetaData);
    const {certifications} = commonMetaDataState

    

    function isObject(value) {
        return Object.prototype.toString.call(value) === '[object Object]';
    }

    const getCertificateLogo = (data) => {
        if (!isObject(data)) {

            switch (data) {
                case "msc":
                    return <img src={msc} alt="" height={45} width={45}></img>
                case "utz":
                    return <img src={utz} alt="" height={45} width={45}></img>
                case "rainforestalliance":
                    return <img src={rainforestalliance} alt="" height={45} width={45}></img>
                case "a":
                    return <img src={nutriscore_a} alt="" height={45} width={100}></img>
                case "b":
                    return <img src={nutriscore_b} alt="" height={45} width={100}></img>
                case "c":
                    return <img src={nutriscore_c} alt="" height={45} width={100}></img>
                case "d":
                    return <img src={nutriscore_d} alt="" height={45} width={100}></img>
                case "e":
                    return <img src={nutriscore_e} alt="" height={45} width={100}></img>
                default:
                    break;
            }
        } else {
            let filterById = certifications?.filter((ele) => ele.id == data.value).pop();
            if (filterById?.image_url) {
                return <img src={filterById?.image_url} alt="certification" height={45} />
            }
        }
    }

    return (
        <>
            {!!value && value.map((data, i) => (
                // not to show MSC certification information even the product data contains MSC reference
                data !== "msc" &&
                <span key={i} className="col-12">
                    {getCertificateLogo(data)}
                </span>
            ))}

        </>
    )
}

export default CertificationsLogo