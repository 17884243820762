import React from 'react'
import { useTranslation } from 'react-i18next';
import _ from 'lodash'

const Header = (props) => {
    const { t, i18n } = useTranslation('common');

    const {value} = props
  return (
    <div>
         <div className="titleDiv">
         <span className="textPrimary productTitle">
           {_.get(value, `${i18n.language}`, '')}
         </span>
       </div>
    </div>
  )
}

export default Header