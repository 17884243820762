import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from "./StCloseButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { requestLoginLinkAction } from "src/redux/thunk/commonThunk";
import { localStorageSave, Keys } from "src/helper/localStorageHelper";

function LoginModal(props) {
  const { showModal, closeModal } = props;
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [showForm, setShowForm] = useState(true);
  const _state = useSelector((state) => state.commonSlice.emailResponse, shallowEqual);

  useEffect(() => {
    if (_state && _state.message === "Ok") {
      setShowForm(false);
    }
  }, [_state]);

  const handleClose = () => {
    setShowForm(true);
    closeModal();
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handleSubmit = () => {
    const data = {
        email: email,
    };
    dispatch(requestLoginLinkAction(data));
    localStorageSave(
      Keys.LOGIN_EMAIL,
      email
    );
};

  return (
    <>
      <Modal
        size="md"
        centered={true}
        backdrop="static"
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <div style={{ position: "absolute", right: 10 }}>
            <StCloseButton
              text={t("Close")}
              onClick={() => handleClose()}
            ></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: "normal" }}>
          {!showForm ?
            <div>You should receive a login link in your email within a couple minutes</div>
          :
            <div>
              <div>Enter your email address to get login link.</div>
              <div className="row">
                <input className="col-10" type={"email"} onChange={(e) => handleEmailChange(e.target.value)} />
                <button type="button" className="col-2 btn btn-success" onClick={handleSubmit}>Send</button>
              </div>
            </div>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginModal;
