import React from 'react'
import "@google/model-viewer";
/** import aircraftWorkshop from "src/assets/3d-model/aircraftWorkshop.hdr" */

function Media3dmodelViewer(props) {
    const { value } = props

    return (
        <>
            <div className="modelViewer" >
                <model-viewer
                    src={value}
                    // ios-src={iosModelPath}
                    // skybox-image={aircraftWorkshop}
                    alt="A 3D model"
                    shadow-intensity="0"
                    camera-controls
                    auto-rotate
                    ar
                    ar-scale
                    autoplay
                    xr-environment
                />
            </div>
        </>
    )
}

export default Media3dmodelViewer