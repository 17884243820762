import _ from 'lodash';

export const DetectUPIDSType = (id) => {
  let patt = /(\d{13}[a-zA-Z]|-\d{13}[a-zA-Z]|-\d{3})/g;
  let result = id.match(patt);
  return result;
}

const getIdFromDigiLink = (path) => {
  let _upids = "";
  let urlParts = path.split("/");
  if (urlParts.length <= 4) {
    _upids = urlParts[2];
  }
  else if (urlParts[4] == "000000000000-0000") {
    _upids = urlParts[2];
  }
  else {
    let indexOfPrimaryKey;
    let indexOfSerialNo;

    if (urlParts.includes('01')) {
      indexOfPrimaryKey = urlParts.indexOf('01');
    }
    if (urlParts.includes('21')) {
      indexOfSerialNo = urlParts.indexOf('21');
    }

    if (urlParts[indexOfSerialNo + 1].includes('A')) {
      let updatedUrlPart = urlParts[indexOfSerialNo + 1].replace("A", "-");
      _upids = urlParts[indexOfPrimaryKey + 1] + "-" + updatedUrlPart;
      // _upids = urlParts[2] + "-" + urlParts[4];
    } else if (urlParts[indexOfSerialNo + 1].includes('-')) {
      _upids = urlParts[indexOfPrimaryKey + 1] + "-" + urlParts[indexOfSerialNo + 1];
    } else {
      let separator = urlParts[indexOfSerialNo + 1].replace(/[^a-z]/gi, "");
      _upids = urlParts[indexOfPrimaryKey + 1] + separator + urlParts[indexOfSerialNo + 1];
    }
  }

  return _upids;
}

export const handleUrl = (path) => {
  let _upids
  if (path.split("/").length > 2) {
    _upids = getIdFromDigiLink(path);
  } else {
    _upids = path.split("/")[1]
  }

  const link = {
    UPIDS: _upids,
    isUpidsProduct: !!DetectUPIDSType(_upids),
  }

  return link
}


export const getActiveTabInfoList = (productTabList, productData, productUpidsData) => {
  let renderProductTabList = []

  productTabList && productTabList[0] && productTabList.map((item) => {
    let isItemPushedInTabList = false
    if (!_.isEmpty(productUpidsData)) {
      item.requiredResponsePath && item.requiredResponsePath[0] && item.requiredResponsePath.some((element) => {
        const requiredResponsePathData = _.get(productUpidsData, `${element}`, "")
        if (element === true || (requiredResponsePathData && !_.isEmpty(requiredResponsePathData))) {
          renderProductTabList.push(item)
          isItemPushedInTabList = true
          return true;
        }
      });
    }
    if (!_.isEmpty(productData) && !isItemPushedInTabList) {
      item.requiredResponsePath && item.requiredResponsePath[0] && item.requiredResponsePath.some((element) => {
        const requiredResponsePathData = _.get(productData, `${element}`, "")
        if (element === true || (requiredResponsePathData && !_.isEmpty(requiredResponsePathData))) {
          renderProductTabList.push(item)
          return true;
        }
      });
    }
  })

  return renderProductTabList
}


export const handleStopVideo = () => {
  try {
    const youTubeVideoHtmlCollection = document.getElementsByClassName("youTubeVideoIframe");
    const youtubeVideoList = [...youTubeVideoHtmlCollection]

    youtubeVideoList && youtubeVideoList[0] && youtubeVideoList.forEach((item) => {
      item.contentWindow.postMessage(`{"event":"command", "func":"pauseVideo", "args":""}`, '*');
    })
    /**
    for (let i = 0; i < youTubeVideoHtmlCollection.length; i++) {
      youTubeVideoHtmlCollection.item(i).contentWindow.postMessage(`{"event":"command", "func":"pauseVideo", "args":""}`, '*');
    }
    */

    const otherVideoHtmlCollection = document.getElementsByClassName("otherVideoElement");
    const otherVideoList = [...otherVideoHtmlCollection]
    otherVideoList && otherVideoList[0] && otherVideoList.forEach((item) => {
      item.pause();
    })

  } catch (error) {
    console.info("error", error)
  }
}

/** 
 * @info hexToRGB
 * console.info("test123", hexToRGB('#dd22d6', 0.7))
 * console.info("test123", hexToRGB('#dd22d6'))
*/
export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgb(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}
