import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getAxiosWithAuth } from 'src/helper/axiosInterceptor';
import { AppConfig } from "src/helper/AppConfig";
import * as _ from 'lodash'

export const getInfoDataAction = createAsyncThunk(
    "common/getInfoDataAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get("/");
            const responseData = response.data
            return responseData;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const requestLoginLinkAction = createAsyncThunk(
    "common/requestLoginLinkAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(`${AppConfig.ioAPIUrl}/iam/emaillink`, arg);
            const responseData = response.data;
            return responseData;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const loginAction = createAsyncThunk(
    "common/loginAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(`${AppConfig.ioAPIUrl}/iam/emaillink/token`, arg);
            const responseData = response.data;
            return responseData;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getUserProfileAction = createAsyncThunk(
    "common/getUserProfileAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxiosWithAuth().get(`${AppConfig.ioAPIUrl}/user`);
            const responseData = response.data;
            return responseData;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const checkProductOwner = createAsyncThunk(
    "common/checkProductOwner",
    async (arg, thunkApi) => {
        try {
            const response = await getAxiosWithAuth().post(`${AppConfig.externalApiUrl}/caller`, arg);
            const responseData = response.data;
            return responseData;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const changeProductOwner = createAsyncThunk(
    "common/changeProductOwner",
    async (arg, thunkApi) => {
        try {
            const response = await getAxiosWithAuth().post(`${AppConfig.externalApiUrl}/caller`, arg);
            const responseData = response.data;
            return responseData;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getPrivacyPolicyDataAction = createAsyncThunk(
    "common/getPrivacyPolicyDataAction",
    async (arg, thunkApi) => {
      try {
        const response = await getAxios("https://prod.api.2nd.gdn/v3/upids/cms/reference/privacy-policy").get();
        return response.data;
      } catch (error) {
        return thunkApi.rejectWithValue([], { data: error.response.data });
      }
    }
  )

