import React from 'react'
import { templateJson } from 'src/template/template';
import { useTranslation } from 'react-i18next';


const Ingredients = (props) => {
    const { value } = props


    return (
        <>
            <div className="row">
                <div className="col-12 pt-10">
                    <span className="ingredients">
                        {value}
                    </span>
                </div>
            </div>
        </>
    )
}

export default Ingredients