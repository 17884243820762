import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const PostalAddress = (props) => {
    const { name, label, value } = props
    const { t } = useTranslation('common');
    return (
        <div className="row mt-10 attrDiv">
            <div className="col-12">
                <span className="attributeTitle">{t(label)}</span>
                {
                    value && value[0] && value.map((ele, index) => {
                        return (
                            <span key={index} className="attributeValue">
                                {ele}
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PostalAddress