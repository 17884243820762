import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";

import { checkProductOwner } from 'src/redux/thunk/commonThunk';
import { localStorageGet, Keys } from 'src/helper/localStorageHelper';
import { handleUrl } from 'src/helper/HelperFunctions';
import { retriveProductDataWithUPIDSAction, retriveProductDataWithGTINAction, getScanCountAction, getDataApiCallFailedAction, isNutritionalDataShownFlag, getAllergensAction, getAllCertificationsData } from 'src/redux/thunk/productThunk';
import { UPIDSAction, updateCommonMetadata, updateMetadataByLocalLang } from 'src/redux/slice/productSlice';
import AppLoader from "src/components/loader";
import Header from "src/components/header";
import AgeRestrictionModal from 'src/components/AgeRestrictionModal';
import DoormatRouterOverlayModal from 'src/components/DoormatRouterOverlayModal.js';
import OverlayUrlModal from 'src/components/basicInfo/OverlayUrlModal';
import SeoHelmet from 'src/components/seo/SeoHelmet';
import UpidsNotFoundModal from 'src/components/UpidsNotFoundModal';
import WarningsModal from 'src/components/WarningsModal';
import SchemaRender from 'src/components/schemaComponent/SchemaRender';
import { useTranslation } from 'react-i18next';



function Product() {


  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('common');
  const { productData, selectedProductSchema, getWarningsForProduct } = useSelector((state) => state.productSlice)
  const _metadataByLocalLang = useSelector((state) => state.productSlice.metadataByLocalLang)



  const gtinFirstTwoCharArray = ['20', '21', '22', '23', '24']
  const currentDate = Date.now() / 1000;


  const getPathString = (str) => {
    if (str.endsWith("/")) {
      str = str.slice(0, -1);
      return str
    }
    else {
      return str
    }
  }
  const _path = getPathString(location.pathname);

  const urlData = handleUrl(_path)

  const [state, setState] = useState({
    locale: "en",
    isLoading: true,
    data: null,
    UPIDS: (urlData && urlData.UPIDS) ? urlData.UPIDS : '',
    isUpidsProduct: (urlData && urlData.isUpidsProduct) ? urlData.isUpidsProduct : false,
    upids_data: {}
  });

  const [ageConfirmModel, setAgeConfirmModel] = useState(false);
  const [doormatRouterOverlayModel, setDoormatRouterOverlayModel] = useState(false);
  const [warningsConfirmModel, setWarningsConfirmModel] = useState(false);
  const [showUpidsNotFoundModal, setShowUpidsNotFoundModal] = useState(false);
  const [warningsDataToDisplay, setWarningsDataToDisplay] = useState([]);
  const [showNutritionalData, setShowNutritionalData] = useState(false);


  useEffect(() => {
    setState({...state,UPIDS:_path.substring(1)})
  }, [_path])

  useEffect(() => {
    getProductData()
  }, [state.UPIDS])
  
  useEffect(() => {
    dispatch(UPIDSAction((urlData && urlData.UPIDS) ? urlData.UPIDS : ''))
  }, [urlData,_path])

  useEffect(() => {
    if (getWarningsForProduct && getWarningsForProduct.length > 0) {
      let updatedWarningsToDisplay = []
      getWarningsForProduct.forEach(function (data, i) {
        if ((currentDate >= data.timeRangeStart) && (currentDate <= data.timeRangeEnd)) {
          updatedWarningsToDisplay.push(data);
        }
      })
      if (updatedWarningsToDisplay.length > 0) {
        setWarningsDataToDisplay(updatedWarningsToDisplay);
        setWarningsConfirmModel(true);
      }
    }
  }, [getWarningsForProduct])

  useEffect(() => {
    dispatch(isNutritionalDataShownFlag(showNutritionalData));
  }, [showNutritionalData]);
  

  useEffect(() => {
    if (productData?.allergens) {
      getAllergenseInfo(productData?.allergens)
    } 
  }, [productData?.allergens,i18n.language])


  const handleOnlyShowNutritionalInfo = () => {
    setShowNutritionalData(true);
    setDoormatRouterOverlayModel(false)
}

const handleFullProfileClick = () =>{
  navigate(`/${productData?.router?.doormat?.fullProfile}`)
  setDoormatRouterOverlayModel(false)
}


  const getAllergenseInfo = async (allergens) => {
    try {
      let localLang = `${localStorageGet('i18nextLng')}`;
      let qCode = getQParaString('ALLERGENS');
      let qLocalLang = `lang=${localStorageGet('i18nextLng')}`;
      let qString = qLocalLang; // Initialize with qLocalLang

      if (qCode && qCode !== null) {
        // Append &qCode only if qCode is present and not null
        qString += `&${qCode}`;
      }

      if (!_.has(_metadataByLocalLang, `${localLang}`)) {
        const response =qCode? await dispatch(getAllergensAction(qString)).unwrap() : []
        let previousMetadata = _.cloneDeep(_metadataByLocalLang);
        _.set(previousMetadata, `${localLang}`, response);
        dispatch(updateMetadataByLocalLang({ ...previousMetadata }))
      }
    } catch (error) {
      console.error("EXCEPTION: ", error)
    }
  }


  const getQParaString = (type) => {
    let contains = _.get(productData, "allergens.list.contains");
    let mayContains = _.get(productData, "allergens.list.may_contain");
    let freeFrom = _.get(productData, "allergens.list.free_from");
    let all = [];
    if (!!contains && contains.length > 0)
      all = [...all, ...contains];
    if (!!mayContains && mayContains.length > 0)
      all = [...all, ...mayContains];
    if (!!freeFrom && freeFrom.length > 0)
      all = [...all, ...freeFrom];
    let _qString = '';
    let uniqueAll = _.uniqBy(all, function (e) {
      return e;
    });
    uniqueAll.forEach((o, i) => {
      i == 0 ? _qString = `code=${o}` : _qString = `${_qString},${o}`
    })
    return _qString;
  }

  const getProductData = async () => {
    if (!!state.UPIDS && state.UPIDS != "") {
      const params = new URLSearchParams(window.location.search);
      const locale = params.get("lang");
      setState({ ...state, isLoading: true })

      if (state.isUpidsProduct) {
        try {
          const upidsDataResponse = await dispatch(retriveProductDataWithUPIDSAction(state.UPIDS)).unwrap()
          const gtin = (upidsDataResponse.gtin == undefined || upidsDataResponse.gtin == "") ? upidsDataResponse.articleIdentifier : upidsDataResponse.gtin;
          setState({ ...state, upids_data: upidsDataResponse })
          getDataWithGtin({ gtin: gtin }, locale);
          scanCountApiCall(state.UPIDS, _.get(_.find(upidsDataResponse.businessIdentifiers, function (t) { return t.type === "VATID" }), 'id', upidsDataResponse.businessIdentifiers[0].id));
        } catch (error) {
          //get data api call failed
          getDataApiCallFailed(state.UPIDS)
          setState({ ...state, isLoading: false })
          getDataWithGtinIfUpidsNotFound(state.UPIDS);
          // console.error("EXCEPTION: ", error)
          // navigate("/error");
        }
        const authState = localStorageGet(Keys.AUTH_STATE);
        const email = localStorageGet(Keys.LOGIN_EMAIL);
        if (authState) {
          await dispatch(checkProductOwner({ url: "/ownership/check", data: { email: email, upidsid: state.UPIDS } })).unwrap();
        }
      } else {
        getDataWithGtin({ gtin: state.UPIDS })
      }

    }
  }

  const getDataWithGtinIfUpidsNotFound = (id) => {
    let masterProductId;
    if (id.includes("-")) {
      let idArray = id.split("-")
      masterProductId = idArray[0]
    } else {
      let segmentSeparator = id.match(/[a-zA-Z]/gi)[0]
      let idArray = id.split(segmentSeparator);
      masterProductId = idArray[0];
    }
    setShowUpidsNotFoundModal(true);
    getDataWithGtin({ gtin: masterProductId, isDataWithGtinIfUpidsNotFound: true });
  }


  const scanCountApiCall = async (UPIDS, businessId) => {
    try {
      await dispatch(getScanCountAction({ UPIDS, businessId })).unwrap()
    } catch (error) {
      console.error("EXCEPTION: ", error)
    }
  }

  const getAllMetaData = async()=>{
    try {
      const allCertifications = await dispatch(getAllCertificationsData()).unwrap();
      let certifications = {
        type : 'certifications',
        data : allCertifications
      }
      dispatch(updateCommonMetadata(certifications));

    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(()=>{
    getAllMetaData();
  },[])


  const getDataWithGtin = async (upids_data, locale) => {
    let gtin = upids_data.gtin
    let checkFirstTwoCharOfGtin = gtin.toString().slice(0, 2)

    if (gtin.toString().length == 8 || (gtin.toString().length == 13 && _.includes(gtinFirstTwoCharArray, checkFirstTwoCharOfGtin))) {
      gtin = gtin.toString();
    } else if ((gtin.toString().length == 13 && !_.includes(gtinFirstTwoCharArray, checkFirstTwoCharOfGtin))) {
      gtin = '0' + gtin.toString();
    } else {
      gtin = gtin.toString();
    }

    try {
      const response = await dispatch(retriveProductDataWithGTINAction(gtin)).unwrap()
      if (!state.isUpidsProduct || upids_data.isDataWithGtinIfUpidsNotFound) {
        scanCountApiCall(upids_data.gtin, _.get(_.find(response.businessIdentifiers, function (t) { return t.type === "VATID" }), 'id', response.businessIdentifiers[0].id))
      }
      let _newData = { ...upids_data, ...response };
      changeDocumentTitle(_newData);
      !!_.get(_newData, 'restricted') && _.get(_newData, 'restricted.type') && _.get(_newData, 'restricted.value') && setAgeConfirmModel(true)
      _.get(_newData,"router.doormat.type") == "nutrients" && !!(_.get(_newData,"router.doormat.fullProfile")) && setDoormatRouterOverlayModel(true)
      setState({
        ...state,
        isLoading: false,
        locale: !!locale ? locale : "en",
      });
    } catch (error) {
      if (!state.isUpidsProduct || upids_data.isDataWithGtinIfUpidsNotFound) {
        getDataApiCallFailed(upids_data.gtin)
      }
      navigate("/error", { state: { productId: gtin } });
      setState({ ...state, isLoading: false })
    }
  };


  const getDataApiCallFailed = async (UPIDS) => {
    try {
      const response = await dispatch(getDataApiCallFailedAction(UPIDS)).unwrap()
    } catch (error) {
      console.error("EXCEPTION: ", error)
    }
  }


  const changeDocumentTitle = (data) => {
    document.title = data?.productName?.[state.locale] ? `UPIDS - ${data.productName?.[state.locale]}` : 'UPIDS'
  }


  const goBack = () => {
    setAgeConfirmModel(false)
    navigate('/')
  }

  const getScoHelmetData = () => {

    if (productData && productData.productType) {
      const scoData = {
        title: "",
        description: "",
        imageUrl: ""
      }


      /** @title */
      if (_.get(productData, "productName.en", "")) {
        scoData.title = _.get(productData, "productName.en", "")
      }
      else if (_.get(productData, "productName.fi", "")) {
        scoData.title = _.get(productData, "productName.fi", "")
      }


      /** @description */
      if (_.get(productData, "descriptionShort.en", "")) {
        scoData.description = _.get(productData, "descriptionShort.en", "")
      }
      else if (_.get(productData, "description.en", "")) {
        scoData.description = _.get(productData, "description.en", "")
      }
      else {
        scoData.description = "UPIDS product info."
      }

      /** @image url */
      if (_.get(productData, "multimedia.primary[0]", "")) {
        scoData.imageUrl = _.get(productData, "multimedia.primary[0]", "")
      }
      else if (_.get(productData, "multimedia.logo[0]", "")) {
        scoData.imageUrl = _.get(productData, "multimedia.logo[0]", "")
      }
      else if (_.get(productData, "multimedia.frontal[0]", "")) {
        scoData.imageUrl = _.get(productData, "multimedia.frontal[0]", "")
      }

      return scoData
    }

    return null
  }

  const scoHelmetData = getScoHelmetData()



  return (
    <>
      {scoHelmetData &&
        <SeoHelmet
          title={scoHelmetData.title}
          description={scoHelmetData.description}
          imageUrl={scoHelmetData.imageUrl}
        />
      }


      <div className="productWrapper">
        <Header />
        {state.isLoading && (
          <AppLoader></AppLoader>
        )}

        {
          productData && productData.id &&
          selectedProductSchema && selectedProductSchema.upidsIoProductTabStructuredSchema && selectedProductSchema.upidsIoProductTabStructuredSchema[0] &&
          <>
            <SchemaRender
              upidsIoProductTabStructuredSchema={selectedProductSchema.upidsIoProductTabStructuredSchema}
            />
          </>
        }
      </div>

      {ageConfirmModel &&
        <AgeRestrictionModal
          ageConfirmModel={ageConfirmModel}
          productData={productData}
          setAgeConfirmModel={setAgeConfirmModel}
          goBack={goBack}
        />
      }

      {doormatRouterOverlayModel &&
        <DoormatRouterOverlayModal
          doormatRouterOverlayModel={doormatRouterOverlayModel}
          productData={productData}
          setDoormatRouterOverlayModel={setDoormatRouterOverlayModel}
          goBack={goBack}
          handleOnlyShowNutritionalInfo={handleOnlyShowNutritionalInfo}
          handleFullProfileClick={handleFullProfileClick}
        />
      }

      {warningsConfirmModel &&
        <WarningsModal
          warningsConfirmModel={warningsConfirmModel}
          warningsData={warningsDataToDisplay}
          setWarningsConfirmModel={setWarningsConfirmModel}
        />
      }
      {showUpidsNotFoundModal &&
        <UpidsNotFoundModal
          showModal={showUpidsNotFoundModal}
          upidsdata={state.UPIDS}
          // productData={productData}
          setShowUpidsNotFoundModal={setShowUpidsNotFoundModal}
        // goBack={goBack}
        />
      }
      <OverlayUrlModal />
    </>
  )
}

export default Product