import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';


const Audio = (props) => {
  const { t, i18n } = useTranslation("common");
  const { value, productID, local, label,uniquekey } = props;
  const [apiError, setApiError] = useState(false);

  const handleAudioError = () => {
    console.error('Audio Error');
    setApiError(true); // Set the error state to true to show the error message
  };

  return (
    <div>
      {productID && (
        <div className='attrDiv'>
          {apiError ? (
            <div className='mt-3 d-flex'>{t("audioNotAvailable")}</div>
          ) : (
            <audio
              key={uniquekey}
              controls
              className="mt-10"
              style={{ width: "100%" }}
              onError={handleAudioError}
            >
              <source src={value} type="audio/mp3" />
            </audio>
          )}
        </div>
      )}
    </div>
  )
}

export default Audio