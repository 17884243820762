import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {  HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { store } from './redux/store';
import LayoutWrapper from 'src/components/layout/LayoutWrapper';
import Product from "src/pages/product";
import Error from "src/pages/error";
import Login from "src/pages/login";
import { AppConfig } from 'src/helper/AppConfig';
import SearchPage from 'src/pages/SearchPage';
/** import { decryptCryptojs } from './helper/cryptoHelper'; */
import AppContextProvider from './AppContext';

const App = () => {
  document.title = AppConfig.AppTitle;

  // const getToken = () => {
  //   return "foobar12345"
  // };
  // window.getToken = getToken;

  /**
   useEffect(() => {
    const decryptData = decryptCryptojs("U2FsdGVkX1/KGZ0e+gUfhq03Sl2JmIxpOu8PeIAsWqKlsBVAYtLUG3Wtya6XpDAG4BHJOR5/rvKCejV9Qbfv/g==")
    console.info("decryptData", decryptData)
   }, [])
  */

  return (
    <AppContextProvider>
    <HelmetProvider>
      <Provider store={store}>
        <LayoutWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/error" element={<Error />} />
              <Route path="/:id/*" element={<Product />} />
              <Route path="/" element={<SearchPage />} />
            </Routes>
          </BrowserRouter>
        </LayoutWrapper>
      </Provider>
    </HelmetProvider>
    </AppContextProvider>
  )
};

export default App;
