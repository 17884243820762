import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import languageList from './languageList.json'

/**
import common_en from './languages/en/translation.json';
import common_de from './languages/de/translation.json';
import common_fi from './languages/fi/translation.json';
import common_sv from './languages/sv-fi/translation.json';
import common_zh from './languages/zh-hans/translation.json';
*/


const getBrowserLocal = () => {
  let _locale = window.navigator.userLanguage || window.navigator.language;
  const trimmedLocale = _locale.trim();
  return trimmedLocale.split(/-|_/)[0];
}




// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init



export const languageListForSelect = languageList.map((item) => {
  // const newValue = item.value.replace(/-/g, "_")
  const trimmedLocale = item.value.trim();
  const newValue = trimmedLocale.split(/-|_/)[0];
  return {
    ...item,
    value: newValue
    }
})

export const lnguageValueList = languageListForSelect.map((item) => item.value)

const getResources = () => {
  const resources = {
    /**
     en: {
        common: common_en
      },
     */
  }

  languageList.forEach((item) => {
    try {
      const lowerCaseValue  = (item.value).toLowerCase();
      const languageJson = require(`./languages/${lowerCaseValue}/translation.json`);
      // const newValue = item.value.replace(/-/g, "_")
      const trimmedLocale = item.value.trim();
      const newValue = trimmedLocale.split(/-|_/)[0];
      resources[newValue] = { common: languageJson }
    } catch (error) { }
  })
  return resources
}

const resourcesObject = getResources()


i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    whitelist: lnguageValueList,
    //detection: langDetectorOptions,
    lng: getBrowserLocal(),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: { ...resourcesObject },
    // react: {
    //   wait: true,
    //   bindI18n: "languageChanged loaded",
    //   bindStore: "added removed",
    //   nsMode: "default"
    // }
  });


export default i18n;