import React from 'react'

function RatingButton(props) {
    const { color, name, value, onClick, selectedValue } = props


    const handleInputChange = () => {
        const e = {
            target: {
                name: "",
                value: ""
            }
        }
        e.target.name = name;
        e.target.value = value
        onClick(e)
    }

    return (
        <button
            className='ratingButton'
            style={{ borderColor: `${color}` }}
            onClick={() => handleInputChange()}
            data-selected={selectedValue == value}
        >
            {value}
        </button>
    )
}

export default RatingButton