import { createSlice, current } from "@reduxjs/toolkit";
import {
    retriveProductDataWithUPIDSAction, retriveProductDataWithGTINAction, getProductTemplateSchemaAction,
    getProductDataFromExternalSource, getAllLinkedUpidsIdData, getWarningsForProductById,
    getNutritionalClaimsLocale, isNutritionalDataShownFlag,getManufacturerPrivacyPolicyContent
} from "../thunk/productThunk";
import _ from "lodash";
import { nutritionalNames, nutritionalQuantityUnit } from "src/template/metadataCollection";

const initialState = {
    isLoading: false,
    UPIDS: "",
    selectedProductSchema: {
        // formType: '', // add/update
        productSchemaType: "",
        productSchema: [],
    },
    productUpidsData: {}, // upids product data
    productData: {}, // gtin /master data
    productDataFromExternalSource: {},
    isDataFromExternalSource: false,
    allLinkedUpidsIdData: [],
    getWarningsForProduct: [],
    getNutritionalClaimsLocaleData: null,
    swiperHeightEventNumber: 1,
    isNutritionalDataShownFlagValue:"",
    metadataByLocalLang : {},
    PrivacyPolicyContent : "",
    commonMetaData : {
        certifications : []
    }
};

const productSlice = createSlice({
    name: "productSlice",
    initialState: initialState,
    reducers: {
        UPIDSAction: (state, action) => {
            state.UPIDS = action.payload;
        },
        swiperHeightAction: (state, action) => {
            const currentState = current(state);
            state.swiperHeightEventNumber = currentState.swiperHeightEventNumber + 1
        },
        updateMetadataByLocalLang : (state, action) =>{
            state.metadataByLocalLang = {...action.payload};

        },
        disableDirectCommerce: (state, action) => {
            return {
                ...state,
                productUpidsData: {
                    ...state.productUpidsData,
                    directCommerce: {
                        ...state.productUpidsData.directCommerce,
                        status: "disable"
                    }
                }
            };
        },
        updateCommonMetadata : (state, action)=>{
            if(action.payload.type === "certifications"){
                state.commonMetaData = {...state.commonMetaData, certifications : action?.payload?.data}
            }
        }
    },
    extraReducers:
        (builder) => {
            builder
                /** RETRIEVE PRODUCT INFO */
                .addCase(retriveProductDataWithUPIDSAction.pending, (state, action) => {
                    state.isLoading = true;
                })
                .addCase(retriveProductDataWithUPIDSAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.productUpidsData = action.payload
                })
                .addCase(retriveProductDataWithUPIDSAction.rejected, (state, action) => {
                    state.isLoading = false;
                })

                // getProductTemplateSchemaAction
                .addCase(getProductTemplateSchemaAction.pending, (state, action) => {
                    state.isLoading = true;
                })
                .addCase(getProductTemplateSchemaAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    const { productSchema, productFlattenSchema, upidsIoProductTabStructuredSchema } = action.payload;
                    // state.selectedProductSchema.
                    state.selectedProductSchema.upidsIoProductTabStructuredSchema = upidsIoProductTabStructuredSchema;
                    state.selectedProductSchema.productFlattenSchema = productFlattenSchema;
                })
                .addCase(getProductTemplateSchemaAction.rejected, (state, action) => {
                    state.isLoading = false;
                })

                .addCase(retriveProductDataWithGTINAction.pending, (state, action) => {
                    state.isLoading = true;
                })
                .addCase(retriveProductDataWithGTINAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.productData = action.payload
                })
                .addCase(retriveProductDataWithGTINAction.rejected, (state, action) => {
                    state.isLoading = false;
                })

                .addCase(getProductDataFromExternalSource.pending, (state, action) => {
                    state.isLoading = false
                })
                .addCase(getProductDataFromExternalSource.fulfilled, (state, action) => {
                    let updatedProductData = mapProductDataWithExternalSource(action.payload.responseData, action.payload.externalResponseProductJson)
                    state.productData = updatedProductData
                    state.isDataFromExternalSource = updatedProductData.isDataFromExternalSourceFlag
                    state.productDataFromExternalSource = action.payload.externalResponseProductJson
                })
                .addCase(getProductDataFromExternalSource.rejected, (state, action) => {
                    state.isLoading = false;
                })

                .addCase(getAllLinkedUpidsIdData.pending, (state, action) => {
                    state.isLoading = false
                })
                .addCase(getAllLinkedUpidsIdData.fulfilled, (state, action) => {
                    state.allLinkedUpidsIdData = action.payload
                })
                .addCase(getAllLinkedUpidsIdData.rejected, (state, action) => {
                    state.isLoading = false
                    state.allLinkedUpidsIdData = []
                })

                .addCase(getWarningsForProductById.pending, (state, action) => {
                    state.isLoading = false
                })
                .addCase(getWarningsForProductById.fulfilled, (state, action) => {
                    state.getWarningsForProduct = action.payload
                })
                .addCase(getWarningsForProductById.rejected, (state, action) => {
                    state.isLoading = false
                    state.getWarningsForProduct = []
                })

                .addCase(getNutritionalClaimsLocale.pending, (state, action) => {
                    state.isLoading = false
                })
                .addCase(getNutritionalClaimsLocale.fulfilled, (state, action) => {
                    state.getNutritionalClaimsLocaleData = action.payload
                })
                .addCase(getNutritionalClaimsLocale.rejected, (state, action) => {
                    state.isLoading = false
                    state.getNutritionalClaimsLocaleData = []
                })

                .addCase(getManufacturerPrivacyPolicyContent.pending, (state, action) => {
                    state.isLoading = false
                })
                .addCase(getManufacturerPrivacyPolicyContent.fulfilled, (state, action) => {
                    state.PrivacyPolicyContent = action.payload[0]?.content
                })
                .addCase(getManufacturerPrivacyPolicyContent.rejected, (state, action) => {
                    state.isLoading = false
                    state.getNutritionalClaimsLocaleData = []
                })

                .addCase(isNutritionalDataShownFlag, (state, action) => {
                    state.isNutritionalDataShownFlagValue = action.payload; // networkConnectivity
                  })

        },
});

const mapProductDataWithExternalSource = (productData, externalProductJson) => {
    let externalData = externalProductJson.product

    !_.get(productData, `ingredients.en`) && _.set(productData, `ingredients.en`, _.get(externalData, `ingredients_text_en`));
    !_.get(productData, `ingredients.fi`) && _.set(productData, `ingredients.fi`, _.get(externalData, `ingredients_text_fi`));
    !_.get(productData, `ingredients.sv`) && _.set(productData, `ingredients.sv`, _.get(externalData, `ingredients_text_sv`));
    !_.get(productData, `ingredients.zh`) && _.set(productData, `ingredients.zh`, _.get(externalData, `ingredients_text_zh`));

    if (!_.get(productData, `ingredients`)) {
        productData.isDataFromExternalSourceFlag = true    //to show notice msg in header section whether data is taken from external source or not
    }

    if (!_.get(productData, 'nutritional.details')) {
        let nutritionalDetails = getNutritionalInfoFromExternalSource(externalData.nutriments)
        if (nutritionalDetails) {
            productData.isDataFromExternalSourceFlag = true     //to show notice msg in header section whether data is taken from external source or not
        }
        _.set(productData, 'nutritional.details', nutritionalDetails);
    }
    return productData
}

const getNutritionalInfoFromExternalSource = (nutriments) => {
    let keys = Object.keys(nutriments);
    let nutrimentsObjectKey = keys.find((key) => {
        // if(key=='energy-kcal_100g' || key=='energy-kj_100g'){
        //     return key
        // }
        return key == 'energy-kcal_100g'
    })
    if (nutrimentsObjectKey) {
        let nutritionalKey = nutrimentsObjectKey.split(/-|_/);
        let nutritionalDetails = getNutritionalData(nutrimentsObjectKey, nutritionalKey, nutriments)
        return nutritionalDetails
    }
}

const getNutritionalData = (nutrimentsObjectKey, nutritionalKey, externalDataNutriments) => {
    let details = []
    let nutritionalDetailsObject = {
        quantity: externalDataNutriments[nutrimentsObjectKey],
        typeCode: _.find(nutritionalNames, ["value", _.capitalize(nutritionalKey[0])]).code,
        nutrientName: { en: _.find(nutritionalNames, ["value", _.capitalize(nutritionalKey[0])]).value },
        quantityUnit: _.find(nutritionalQuantityUnit, ["symbol", nutritionalKey[1]]).symbol
    }
    details.push(nutritionalDetailsObject)
    return details
}

export const { UPIDSAction, swiperHeightAction, disableDirectCommerce, updateMetadataByLocalLang, updateCommonMetadata } = productSlice.actions;
export default productSlice;
