
 import React, { useState } from "react";
 
 const SwipeLeftIconComponent = () => {

   return (
        <div className='swipeIcon'>
            <i className="bi bi-hand-index-thumb"></i>
        </div>
   );
 };
 
 export default SwipeLeftIconComponent;
 