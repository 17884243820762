import React, { useState } from "react";
import { useSelector } from "react-redux";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./d3TreeHelper";
import { AppConfig } from "src/helper/AppConfig";
import _ from 'lodash';
import { useTranslation } from "react-i18next";




export default function JsonTree(props) {
    const { allLinkedUpidsIdData } = props
    
    const { t, i18n } = useTranslation('common');

    const productState = useSelector((state) => state.productSlice)
    const [hovered, setHovered] = useState({
        status: false,
        upidsId: ''
    });

    const { UPIDS, productUpidsData } = productState

    const modifyBatchIds = (data) => {
        return data.map((item) => {
            if (data.length !== 0) {
                const modifiedItem = { ...item };
                let newItem = {};
                // Create a new object without the batchIds array
    
                newItem = {
                    productName: _.get(modifiedItem, `productName.${i18n.language}`, "upidsId"),
                    upidsId: modifiedItem.upidsId,
                    children: modifiedItem.children ? modifyBatchIds(modifiedItem.children) : [],
                };

                // If batchIds array exists, add new objects to children
                if (modifiedItem.batchIds && modifiedItem.batchIds.length > 0) {
                    modifiedItem.batchIds.forEach((batchId) => {
                        newItem.children.unshift({
                            type: batchId?.type,
                            upidsId: batchId.id
                        });
                    });
                }
                return newItem;
            }
        });
    };


    const children =
    {
        "productName": _.get(productUpidsData, `productName.${i18n.language}`, _.get(productUpidsData, 'upidsId')),
        "upidsId": _.get(productUpidsData, "upidsId", ""),
        children: modifyBatchIds(allLinkedUpidsIdData?.data[0].children)
    }
    if(_.has(productUpidsData, "batchIds")){
        let batchIdOfFirstNode = productUpidsData["batchIds"]?.map((ele, idx)=>{
            return {
                type : ele?.type,
                upidsId : ele?.id
            }
        })
        batchIdOfFirstNode.forEach((elements)=>{
            children?.children.push(elements)
        })
    }

    const [dimensions, translate, containerRef] = useCenteredTree();


    const containerStyles = {
        width: "100%",
        height: "90vh"
    };

    const d3TreeDimention = {
        zoom: 0.5,
        nodeSize: {
            x: 300,
            y: 200
        },
    }

    const foreignObjectProps = { width: d3TreeDimention.nodeSize.x, height: d3TreeDimention.nodeSize.y, x: 20 };


    // Here we're using `renderCustomNodeElement` to represent each node
    const renderForeignObjectNode = ({ nodeDatum, toggleNode, foreignObjectProps }) => {
        let seperator = " "
        let splitNameList = []
        // let isBatchIds = _.has(nodeDatum, 'batchIds')
        let isUpidsUndefined = nodeDatum?.upidsId
        let isBatchIdType = _.has(nodeDatum, 'type')
       

        if (!isBatchIdType) {
            const seperatorList = nodeDatum?.upidsId?.match(/[\-A-Za-z]/g)
            seperator = seperatorList[0]
            splitNameList = nodeDatum.upidsId.split(seperator)
        }

        return (
            <g>
                <circle r="15" onClick={toggleNode} />
                <foreignObject {...foreignObjectProps}>
                    {
                        isBatchIdType ?
                            <div style={{ border: "1px transparent", backgroundColor: "transparent", textAlign: 'center', fill: "blue" }}>
                                <h3 style={{ textAlign: "left", fill: "blue" }}>{nodeDatum?.type}-{nodeDatum?.upidsId}</h3>
                            </div>
                            :
                            <>
                                {_.has(nodeDatum, 'productName') && <div onMouseEnter={() => setHovered({ upidsId: nodeDatum?.upidsId, status: true })} onMouseLeave={() => setHovered({ upidsId: '', status: false })}>
                                    <a target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }} href={`${AppConfig.b2cAppUrl}/${nodeDatum.upidsId}`}>
                                        <div style={{ border: "1px transparent", backgroundColor: "transparent", textAlign: 'center', fill: "blue" }}>
                                            <h2 style={{ textAlign: "left", fill: "blue" }}>
                                                {_.has(nodeDatum, `productName`) ? _.get(nodeDatum, `productName`, `upidsId`) : _.get(nodeDatum, 'upidsId')}
                                            </h2>
                                        </div>
                                    </a>
                                </div>}
                                {
                                    !_.has(nodeDatum, 'productName') ? <a target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }} href={`${AppConfig.b2cAppUrl}/${nodeDatum.upidsId}`}>
                                        <div style={{ border: "1px transparent", backgroundColor: "transparent", textAlign: 'center', fill: "blue" }}>
                                            {splitNameList && splitNameList[0] && splitNameList.map((item, index) => (
                                                <h2 key={index} style={{ textAlign: "left", fill: "blue", fontWeight: "bold" }}>
                                                    {(index !== 0) && seperator}{item}
                                                </h2>
                                            ))}
                                        </div>
                                    </a> :
                                        (hovered.upidsId === nodeDatum?.upidsId && hovered.status) && <a target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }} href={`${AppConfig.b2cAppUrl}/${nodeDatum.upidsId}`}>
                                            <div style={{ border: "1px transparent", backgroundColor: "transparent", textAlign: 'center', fill: "blue" }}>
                                                {splitNameList && splitNameList[0] && splitNameList.map((item, index) => (
                                                    <p key={index} style={{ textAlign: "left", fill: "blue", fontWeight: "bold" }}>
                                                        {(index !== 0) && seperator}{item}
                                                    </p>
                                                ))}
                                            </div>
                                        </a>
                                }
                            </>
                    }
                </foreignObject>
            </g>
        )
    };

    return (
        <div style={containerStyles} ref={containerRef} >
            <Tree
                data={children}
                zoom={d3TreeDimention.zoom}
                nodeSize={d3TreeDimention.nodeSize}
                dimensions={dimensions}
                translate={translate}
                renderCustomNodeElement={(rd3tProps) =>
                    renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                }
                orientation="horizontal"
            />
        </div>
    );
}
