import React from 'react'
import { useTranslation } from 'react-i18next';

const TextMultiBadges = (props) => {
    const { t, i18n } = useTranslation('common');

    return (
        <>
            <div id="nutrition">
                <div className="productSubTitle text-uppercase pt-3">{t('Claims')}</div>
                {props && props.value.map((item, index) => (
                    <span key={index} className="st-badge blue-badge" >
                        {item}
                    </span>
                ))}
            </div>
        </>
    )
}

export default TextMultiBadges