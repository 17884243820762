import React from 'react'
import _ from 'lodash'

const Identifier = (props) => {
  const { value, label, productData, field } = props

  const isGtinAvailable = () => {
    let isShow = false;
    const gtin = _.has(productData, 'gtin');
    if (!gtin) {
      isShow = false
    } else {
      isShow = true
    }
    return isShow
  }

  const currentIdentifier = isGtinAvailable();
  return (
    <>
      {currentIdentifier && field.name === 'gtin' &&
        <div className="row mt-10 attrDiv">
          <div className="col-12">
            <span className="attributeTitle">{label}</span>
            <span className="attributeValue">
              {value && value}
            </span>
          </div>
        </div>
      }
      {!currentIdentifier && field.name === "_2an" &&
        <div className="row mt-10 attrDiv">
          <div className="col-12">
            <span className="attributeTitle">{label}</span>
            <span className="attributeValue">
              {value && value}
            </span>
          </div>
        </div>
      }
    </>
  )
}

export default Identifier