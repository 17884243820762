import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import SCText from './SCText';
import { Additives } from './Additives';
import Feedback from '../feedback';
import Certifications from './Certifications';
import Banner from './Banner';
import TextMultiBadges from './TextMultiBadges';
import Nutritional from './Nutritional';
import ExternalLinks from './ExternalLinks';
import Links from './Links';
import PdfDownloader from './PdfDownloader';
import MediaImage from './MediaImage';
import Media3dmodelViewer from '../Media3dmodelViewer';
import VideoViewer from '../VideoViewer';
import Ingredients from './Ingredients';
import Allergens from './Allergens';
import LinkedUpids from '../LinkedUpids';
import { useDispatch, useSelector } from 'react-redux';
import CertificationsLogo from './CertificationsLogo';
import ExternalSources from './ExternalSources';
import Header from './Header';
// import DirectCommerce from './DirectCommerce';
import { AppConfig } from "src/helper/AppConfig";
import Audio from './Audio';
import { useLocation, useParams } from "react-router-dom";
import PostalAddress from './PostalAddress';
import { handleUrl } from 'src/helper/HelperFunctions'
import UniqueIdentifier from './UniqueIdentifier';
import Dates from './Dates';
import DirectCommerce from './DirectCommerce';
import QrcodeViewer from './QrcodeViewer';
import Identifier from './Identifier';
import BatchIds from './BatchIds';

const FieldSchemaRender = (props) => {
  const { field, selectedFieldLanguage, productData } = props
  const { productUpidsData } = useSelector((state) => state.productSlice)
  const { t, i18n } = useTranslation("common");
  const location = useLocation()
  const _state = useSelector((state) => state.productSlice)
  const _commonState = useSelector((state) => state.commonState)
  const fieldType = _.get(field, "upidsIo.type", "")
  const { id } = useParams();

  const getPathString = (str) => {
    if (str.endsWith("/")) {
      str = str.slice(0, -1);
      return str
    }
    else {
      return str
    }
  }

  const _path = getPathString(location.pathname);
  const urlData = handleUrl(_path)


  if (fieldType === "mediaImage") {
    let value
    const valueFromField = _.get(field, "upidsIo.value", "")
    const valueFromProductData = _.get(productData, field.nameUpdated, "")
    if (valueFromField) {
      value = valueFromField
    } else if (valueFromProductData) {
      value = valueFromProductData
    }

    if (value) {
      return (
        <>
          <MediaImage
            key={JSON.stringify(field)}
            field={field}
            name={field.name}
            label={_.get(field, "upidsIo.label", "")}
            value={value}
          />
        </>
      )
    }
  }


  if (fieldType === "media3dmodel") {
    let value
    const valueFromField = _.get(field, "upidsIo.value", "")
    const valueFromProductData = _.get(productData, field.nameUpdated, "")
    if (valueFromField) {
      value = valueFromField
    } else if (valueFromProductData) {
      value = valueFromProductData
    }

    if (value) {
      return (
        <>
          <Media3dmodelViewer
            value={value}
          />
        </>
      )
    }
  }

  if (fieldType === "uniqueIdentifier") {
    let value
    if (_.has(productUpidsData, `upidsId`)) {

      const getPathString = (str) => {
        if (str.endsWith("/")) {
          str = str.slice(0, -1);
          return str
        }
        else {
          return str
        }
      }
      const _path = getPathString(location.pathname);

      const urlData = handleUrl(_path)

      value = (urlData && urlData.UPIDS) ? urlData.UPIDS : ''
    }


    if (value) {
      return (
        <>
          <UniqueIdentifier
            value={value}
            label={t(_.get(field, "upidsIo.label", ""))}
          />
        </>
      )
    }
  }




  if (fieldType === "video") {
    let value
    const valueFromField = _.get(field, "upidsIo.value", "")
    const valueFromProductData = _.get(productData, field.nameUpdated, "")
    if (valueFromField) {
      value = valueFromField
    } else if (valueFromProductData) {
      value = valueFromProductData
    }

    if (value) {
      return (
        <>
          <VideoViewer
            value={value}
          />
        </>
      )
    }
  }

  if (fieldType === "text") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}.${selectedFieldLanguage}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }
    if (value) {
      return (
        <SCText
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={t(_.get(field, "upidsIo.label", ""))}
          value={value}
        />
      )
    }
  }


  if (fieldType === "banner") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}.${selectedFieldLanguage}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }

    const bgColor = _.get(productData, 'marketing.custom.bgColor', "#22323d")

    if (value) {
      return (
        <>
          <Banner
            value={value}
            bgColor={bgColor}
          />
        </>
      )
    }

  }

  if (fieldType === "certifications") {
    const certifications = _.get(productData, "certifications", null)

    if (certifications && certifications[0]) {
      return (
        <>
          <Certifications
            value={certifications}
          />
        </>
      )
    }
  }


  if (fieldType === "ingredients") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}.${selectedFieldLanguage}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }

    return (
      <>
        <Ingredients
          value={value}
        />
      </>
    )
  }

  if (fieldType === "additive") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}.${selectedFieldLanguage}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }

    if (value) {
      return (
        <Additives
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }


  if (fieldType === "allergens") {
    let value = ""

    if (field && field.isMultilingual && false) {
      value = _.get(productData, `${field.nameUpdated}.${selectedFieldLanguage}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }

    if (value) {
      return (
        <Allergens
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }


  if (fieldType === "textMultiBadges") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }


    if (value) {
      return (
        <TextMultiBadges
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }


  if (fieldType === "nutritional") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }


    if (value) {
      return (
        <Nutritional
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }

  if (fieldType === "externalLinks") {

    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }


    if (value) {
      return (
        <ExternalLinks
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={t(_.get(field, "upidsIo.label", ""))}
          value={value}
        />
      )
    }
  }


  if (fieldType === "links") {

    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }


    if (value) {
      return (
        <Links
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }



  if (fieldType === "pdfDownloader") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }


    if (value) {
      return (
        <PdfDownloader
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }

  if (fieldType === "ingredients") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }


    if (value) {
      return (
        <Ingredients
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }

  if (fieldType === "header") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }


    if (value) {
      return (
        <Header
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }

  if (fieldType === "qrCodeViewer") {
    const value = _.get(field, "upidsIo.value", "")
    
    if (value) {
      return (
        <>
          <QrcodeViewer
            value={value}
          />
        </>
      )
    }
  }


  if (fieldType === "certificationsLogo") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }


    if (value) {
      return (
        <CertificationsLogo
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }

  if (fieldType === "externalSources") {
    let value = ""

    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }


    if (value) {
      return (
        <ExternalSources
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }

  if (fieldType === "identifier") {

    let value = ""
    if (field && field.isMultilingual) {
      value = _.get(productData, `${field.nameUpdated}`, "")
    }
    else {
      value = _.get(productData, field.nameUpdated, null)
    }
    if (value) {
      return (
        <Identifier
          key={JSON.stringify(field)}
          field={field}
          name={field.name}
          label={t(_.get(field, "upidsIo.label", ""))}
          value={value}
          productData ={productData}
        />
      )
    }
  }

  if (fieldType === "batchIds") {
    let allBatchIds = _.get(productUpidsData, `batchIds`, "")

    if (allBatchIds?.length > 0) {
      return (
        <>
          {allBatchIds && allBatchIds[0] && allBatchIds?.map((item) => (
            <React.Fragment>
              <BatchIds
                indBatchIdValue={item}
                label={t(_.get(field, "upidsIo.label", ""))}
              />
            </React.Fragment>

          ))}

        </>
      )
    }

  }

  if (fieldType === "dates") {

    let value = [
      // {
      //   label: '',
      //   value: ''
      // }
    ]

    const createdDate = _.get(productData, `createdDate`, null);
    const productExpires = _.get(productData, `productExpires`, null)
    const getDates = (createdDate, productExpires) => {
      if (createdDate) {
        value.push({
          label: 'Date added on',
          date: createdDate
        })
      }
      if (productExpires) {
        value.push({
          label: 'Expiration Date',
          date: productExpires
        })
      }
      return value
    }

    const allDates = getDates(createdDate, productExpires)

    if (createdDate && productExpires) {
      return (
        <Dates
          // key={JSON.stringify(field)}
          // field={field}
          // name={field.name}
          // label={_.get(field, "upidsIo.label", "")}
          value={allDates}
        />
      )
    }
  }

  if (fieldType === "postalAddress") {
    const postalAddress = _.get(productData, `marketing.postalAddress`, '')

    if (postalAddress) {
      return (
        <PostalAddress
          label={_.get(field, "upidsIo.label", "")}
          value={postalAddress}
        />
      )
    }
  }


  if (fieldType === "audio") {

    let sourceSrc;
    const gtin = _.get(productData, `gtin`,``)
    const _2an= _.get(productData, `_2an`,``)
    let productID = !!gtin ? gtin :_2an;
    if (productID) {
      sourceSrc = `${AppConfig.SERVER_URL}/services/text-to-speech?gtin=${productID}&lang=${i18n.language}`;
    }


    if (sourceSrc) {

      return (
        <Audio
          uniquekey={`${productID}_${i18n.language}`}
          local={i18n.language}
          productID={productID}
          label={_.get(field, "upidsIo.label", "")}
          value={sourceSrc}
        />
      )
    }
  }


  if (fieldType === "directCommerce") {
    // const pathNameParts = _path.split("-");
    // const flagForUpidsProduct = pathNameParts.length >= 2;
    const masterValue = _.get(productData, `directCommerce`, null)
    const UPIDSValue = _.get(productUpidsData, `directCommerce`, null)
    // let value = "";

    // if(["sell","presale"].includes(masterValue?.status) && !["sell","presale"].includes(UPIDSValue?.status)  ){
    //   value = masterValue;
    // }
    // else if(!["sell","presale"].includes(masterValue?.status) && ["sell","presale"].includes(UPIDSValue?.status)){
    //   if(flagForUpidsProduct){
    //     value = UPIDSValue
    //   }else{
    //     value = masterValue;
    //   }
    // }else if(!["sell","presale"].includes(masterValue?.status) && !["sell","presale"].includes(UPIDSValue?.status)){
    //   if(flagForUpidsProduct){
    //     value = UPIDSValue
    //   }else{
    //     value = masterValue
    //   }
    // }else if(["sell","presale"].includes(masterValue?.status) && ["sell","presale"].includes(UPIDSValue?.status)){
    //   if(flagForUpidsProduct){
    //     value = UPIDSValue
    //   }else{
    //     value = masterValue
    //   }
    // }else{

    // }
    const value = !!masterValue && !!UPIDSValue ? UPIDSValue : masterValue;
    if (value) {
      return (
        <DirectCommerce
          // key={JSON.stringify(field)}
          // field={field}
          // name={field.name}
          // isUpidsProduct={isUpidsProduct}
          productData={productData}
          urlData={urlData}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }

  if (fieldType === "linked") {

    let value = _state && _state.productUpidsData

    if (value) {
      return (
        <LinkedUpids
          // key={JSON.stringify(field)}
          // field={field}
          // name={field.name}
          label={_.get(field, "upidsIo.label", "")}
          value={value}
        />
      )
    }
  }

  if (fieldType === "feedback") {

    return (
      <Feedback
        key={JSON.stringify(field)}
      />
    )
  }



  return (
    <>

    </>
  )
}


FieldSchemaRender.defaultProps = {
  fieldList: [],
  field: {
    name: "",
    label: "",
    type: "",
    value: null,
  },
  // formValidationObject: {
  //     isAllTouched: false,
  //     touched: {},
  //     error: {},
  // },
  onBlur: () => { },
  onChange: () => { },
};


export default FieldSchemaRender