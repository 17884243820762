import React from 'react';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function WarningsModal(props) {
    const { warningsConfirmModel, warningsData, setWarningsConfirmModel } = props
    const { t, i18n } = useTranslation('common');
    

    return (
        <>
            <Modal size="md" centered={true} backdrop="static" show={warningsConfirmModel} onHide={() => setWarningsConfirmModel(false)} >
                <Modal.Header style={{ paddingRight: "0.1rem" }} >
                    <span className="col-9" style={{ fontSize: 18, fontWeight: 'bold' }} >{t("Warnings")}</span>
                    <span className="close-text col-3" style={{marginRight: "-.5rem"}} onClick={() => setWarningsConfirmModel(false)} >
                        <MdClose style={{ color: 'black' }} fontSize={24} fontWeight={'bold'} />
                        {t('Close')}
                    </span>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                    <div className="row m-0 justify-content-center">

                        <div className="form-group col-12 m-2"  >
                            {warningsData.length > 0 && warningsData.map((data, i) => (
                                <>
                                    <p style={{ color: '#d1334c' }} key={i}>{data.warningText[i18n.language] ? data.warningText[i18n.language] : data.warningText.en}</p>
                                </>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <Button
                        className="confirm_button"
                        variant="primary"
                        type="button"
                        onClick={() => setWarningsConfirmModel(false)}
                    >{t('Ok')}</Button>
                    {/* <Button
                        className="confirm_button"
                        variant="primary"
                        type="button"
                        onClick={()=>setWarningsConfirmModel(false)}
                    >{t('No')}</Button> */}
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default WarningsModal