import axios from "axios";
/**
import Cookies from 'js-cookie';
import store from "src/redux/store";
*/
import { AppConfig } from "./AppConfig"
import { localStorageGet, Keys } from './localStorageHelper';

/**
 * Interceptors are a feature that allows an application to intercept requests or responses before they are handled by the .then() or the .catch().
 * There are 2 type of interceptor 1) interceptors.request   &&   2) interceptors.response
 * Both types of Axios interceptors accept two functions.
 * The first function of the request interceptor modifies the request if it’s a valid, successful request,
 * the second function handles when the request is invalid and throws an error.
**/


export const getAxios = (url) => {
    const instance = axios.create();

    if (url) {
        instance.defaults.baseURL = url;
    } else {
        instance.defaults.baseURL = AppConfig.SERVER_URL;
    }

    // interceptors Request------------------------------------
    instance.interceptors.request.use(
        async (config) => {
            /**
            const userToken = Cookies.get('appToken');
            const token = userToken ? JSON.parse(userToken) : '';
             */
            const token = true

            if (token) {
                config.headers = {
                    ...config.headers,
                    // Authorization: "bearer " + token
                };
            }
            return config;
        },
        async (error) => {
            return new Promise((resolve, reject) => {
                console.info("resolve", resolve)
                reject(error);
            });
        }
    );

    //validating the token expiration scenario --------------------------
    // interceptors Response------------------------------------
    instance.interceptors.response.use(
        async (Response) => {
            return Response
        },
        async (error) => {
            if (error.response && error.response.status === 401) {
                //dispatch action using store to show token expire popup-----
                // Cookies.remove('hrmAuth')
                window.location.pathname = "/"
                return new Promise((resolve, reject) => {
                    console.info("resolve", resolve)
                    reject(error);
                });
            } else {
                return new Promise((resolve, reject) => {
                    console.info("resolve", resolve)
                    reject(error);
                });
            }
        }
    );

    return instance;
}

export const getAxiosWithAuth = () => {

    var _authState = localStorageGet(Keys.AUTH_STATE);

    var requestOptions = {

        withCredentials: false,
        headers: {
            Authorization: 'Bearer ' + _authState.token,
        }
    }
    var instance = axios.create(requestOptions);

    instance.interceptors.response.use(
        response => {
            if (response.status === 401 || response.status === 403) {
                alert('Invalid Token please login again')
            }
            return response
        }, error => {
            if (!!error.response && (error.response.status === 401 || error.response.status === 403)) {
                alert('Invalid Token please login again')
            }
            return Promise.reject({ ...error })
        }
    )
    return instance;
}
