import React from 'react'
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import FieldSchemaRender from './FieldSchemaRender';
import { FieldSwiperSlideWrapper } from './FieldSwiperSlideWrapper';



const FieldSchemaWrapper = (props) => {
    const { tabInfo, fieldList, productData, productUpidsData, selectedFieldLanguage } = props
    const { t } = useTranslation("common");


    const handleFieldListRecurssion = (field) => {

        if (field && field.section && field.fieldList && field.fieldList[0]) {

            if (field.swiperSlide === true) {
                return (
                    <React.Fragment>
                        <section className='swiperSection'>
                            {
                                (field.section && field.sectionTitle) &&
                                <>
                                    <div className="titleDiv">
                                        <span className="productSubTitle text-uppercase">
                                            {t(field.section)}
                                        </span>
                                    </div>
                                </>
                            }
                            <FieldSwiperSlideWrapper
                                handleFieldListRecurssion={handleFieldListRecurssion}
                                field={field}
                                productData={productData}
                            />
                        </section>
                    </React.Fragment>
                )
            }
            else {
                return (
                    <React.Fragment>
                        <section className="mt-1">
                            {
                                (field.section && field.sectionTitle) &&
                                <>
                                    <div className="titleDiv">
                                        <span className="productSubTitle text-uppercase">
                                            {t(field.section)}
                                        </span>
                                    </div>
                                </>
                            }
                            {
                                field.fieldList && field.fieldList[0] && field.fieldList.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {handleFieldListRecurssion(item)}
                                    </React.Fragment>
                                ))
                            }
                        </section>
                    </React.Fragment>
                )
            }
        }
        else if (field && field.subSection && field.fieldList) {
            return (
                <div className='row mt-20 attrDiv'>
                    {
                        (field.subSection && field.subSectionTitle) &&
                        <div className='mt-01'>
                            <span className="attributeTitle">
                                {t(field.subSection)}
                            </span>
                        </div>
                    }
                    {
                        field.fieldList && field.fieldList[0] && field.fieldList.map((item, index) => (
                            <React.Fragment key={index}>
                                {handleFieldListRecurssion(item)}
                            </React.Fragment>
                        ))
                    }
                </div>
            )
        }
        else if (field && field.name) {

            if (field && field.upidsIo && field.upidsIo.swiperSlide) {
                return (
                    <React.Fragment >
                        <SwiperSlide key={JSON.stringify(field.upidsIo)}>
                            <FieldSchemaRender
                                field={field}
                                productData={productData}
                                selectedFieldLanguage={selectedFieldLanguage}
                                productUpidsData={productUpidsData}
                            />
                        </SwiperSlide>
                    </React.Fragment>
                )
            }
            else {
                return (
                    <React.Fragment >
                        <FieldSchemaRender
                            field={field}
                            productData={productData}
                            selectedFieldLanguage={selectedFieldLanguage}
                        />
                    </React.Fragment>
                )
            }

        }
    }




    return (
        <React.Fragment>
            {
                _.get(tabInfo, "fieldList", []).map((field, index) => (
                    <React.Fragment key={`schemaRender-fieldList-${index}`}>
                        {handleFieldListRecurssion(field)}
                    </React.Fragment>
                ))
            }
            {
                _.get(tabInfo, "fieldList", []).length === 0 &&
                <>
                    <section className="d-flex justify-content-center p-2">
                        {t("No data found")}
                    </section>
                </>
            }
        </React.Fragment>
    )

    return (
        <>

        </>
    )
}

export default FieldSchemaWrapper