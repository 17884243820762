import React from 'react'
import { BiDownload } from 'react-icons/bi'
import { useTranslation } from 'react-i18next';

const PdfDownloader = (props) => {
    const { value } = props
    const { t, i18n } = useTranslation('common');

    const getDocFileName = (fileUrl) => {
        let _split = fileUrl.split("/");
        return (_split[_split.length - 1])
    }
    return (
        <div className='row mt-10 attrDiv"'>
            <div className="col-12">
                <span className="attributeTitle">{t('Other files')}</span>
                <div className="row">
                    {value && value[0] && value.map((o, index) => (
                        <a key={index} href={o} target="_blank" download>
                            <span className="attributeValue" style={{ fontSize: 15 }}>{getDocFileName(o)}
                                <BiDownload title='download file' style={{ cursor: 'pointer', color: '#dc3545', marginLeft: 5, marginBottom: 3 }} fontSize={20} fontWeight={'bold'} />
                            </span>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PdfDownloader