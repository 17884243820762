import React from 'react'
import { QRCodeSVG } from 'qrcode.react';

const QrcodeView = (props) => {
  const { value } = props

  return (
    <div className="imageSliderSection__item">
      <QRCodeSVG id='img1' size={200} style={{ marginTop: 10 }} value={value && value} />,
    </div>
  )
}

export default QrcodeView