/**
 * Basic details of the product
 */
import React from "react";
import { useTranslation } from 'react-i18next';
import _ from "lodash";

const GrainpassData = (props) => {
  const { t } = useTranslation('common');

  const getGrainPassUI = () => {
    return (
      <>
        {!!_.get(props, 'grainpassData.delivery.varietyName') &&
          <div className="row mt-10 attrDiv">
            <div className="col-12">
              <span className="attributeTitle">{t('Variety name')}</span>
              <span className="attributeValue">
                {_.get(props, 'grainpassData.delivery.varietyName')}
              </span>
            </div></div>}
        {!!_.get(props, 'grainpassData.delivery.harvestYear') &&
          <div className="row mt-10 attrDiv">
            <div className="col-12">
              <span className="attributeTitle">{t('Harvest year')}</span>
              <span className="attributeValue">
                {_.get(props, 'grainpassData.delivery.harvestYear')}
              </span>
            </div></div>}
        {!!_.get(props, 'grainpassData.delivery.batchId') &&
          <div className="row mt-10 attrDiv">
            <div className="col-12">
              <span className="attributeTitle">{t('Batch ID')}</span>
              <span className="attributeValue">
                {_.get(props, 'grainpassData.delivery.batchId')}
              </span>
            </div></div>}
        {!!_.get(props, 'grainpassData.delivery.productionMethod') && _.get(props, 'grainpassData.delivery.productionMethod') === "ORGANIC" &&
          <div className="row mt-10 attrDiv">
            <div className="col-12">
              <span className="attributeTitle">{t('Production method')}</span>
              <span className="attributeValue">
                {_.get(props, 'grainpassData.delivery.productionMethod')}
              </span>
            </div></div>}
        {!!_.get(props, 'grainpassData.delivery.supervisor') &&
          <div className="row mt-10 attrDiv">
            <div className="col-12">
              <span className="attributeTitle">{t('Organic certifier code')}</span>
              <span className="attributeValue">
                {_.get(props, 'grainpassData.delivery.supervisor')}
              </span>
            </div>
          </div>}
      </>
    )
  }



  return (
    <>
      {props.isEmbed ? (
        <>
          {getGrainPassUI()}
        </>
      ) :
        (
          <section id="grainpass" >
            <div className="titleDiv">
              <span className="productSubTitle text-uppercase">
                {t("Grainpass Info")}
              </span>
            </div>
            {getGrainPassUI()}
          </section>
        )
      }

    </>
  )
};



export default GrainpassData;
