import React, { useState, useEffect } from 'react'
import { Pagination, Navigation } from "swiper";
import { Swiper, } from "swiper/react";
import { useParams } from "react-router-dom";
import _ from 'lodash'
import { AppConfig } from 'src/helper/AppConfig';
import { handleStopVideo } from 'src/helper/HelperFunctions';

export const FieldSwiperSlideWrapper = (props) => {
    const { handleFieldListRecurssion, field, productData } = props
    const [swiperIndex, setSwiperIndex] = useState(0)
    const { id } = useParams();



    useEffect(() => {
        handleStopVideo()
        return () => {
        }
    }, [swiperIndex])


    const mediaList = () => {
        const newMediaList = []
        field && field.fieldList && field.fieldList.forEach(element => {
            const value = _.get(productData, element.nameUpdated, "")
            const type = _.get(element, `upidsIo.type`, "")


            if (type === "media3dmodel") {
                value && value[0] && value.forEach((item) => {
                    if (item.split('.').pop() === 'glb') {
                        const newMediaItem = JSON.parse(JSON.stringify(element))
                        newMediaItem.upidsIo.value = item
                        newMediaItem.upidsIo.type = "media3dmodel"
                        newMediaList.unshift(newMediaItem)
                    }
                })
            }
            else if (type === "mediaImage" && value) {
                // newMediaList.push(element)
                const extention = value.split(".").pop().toLowerCase()
                if ((extention === 'jpg') || (extention === 'jpeg') || (extention === 'png') || (extention === 'jfif')) {
                    const newMediaItem = JSON.parse(JSON.stringify(element))
                    newMediaItem.upidsIo.value = value
                    newMediaList.push(newMediaItem)
                }
            }
            else if (type === "mediaImageOther") {
                value && value[0] && value.forEach((item) => {
                    const extention = item.split(".").pop().toLowerCase()
                    if ((extention === 'jpg') || (extention === 'jpeg') || (extention === 'png') || (extention === 'jfif')) {
                        const newMediaItem = JSON.parse(JSON.stringify(element))
                        newMediaItem.upidsIo.value = item
                        newMediaItem.upidsIo.type = "mediaImage"
                        newMediaList.push(newMediaItem)
                    }
                })
            }
            else if (type === "video") {
                value && value[0] && value.forEach((item) => {
                    if (item.split('.').pop() === 'mp4') {
                        const newMediaItem = JSON.parse(JSON.stringify(element))
                        newMediaItem.upidsIo.value = item
                        newMediaItem.upidsIo.type = "video"
                        newMediaList.push(newMediaItem)
                    }
                })
            }
            else if (type === "videoUrl") {
                value && value[0] && value.forEach((item) => {
                    const newMediaItem = JSON.parse(JSON.stringify(element))
                    newMediaItem.upidsIo.value = item
                    newMediaItem.upidsIo.type = "video"
                    newMediaList.push(newMediaItem)
                })
            }

        });

        if (_.isEmpty(newMediaList) && _.get(productData, `media.images.1024`, '')) {
            newMediaList.push({
                name: "media",
                label: "",
                placeholder: "",
                tooltipText: "",
                type: "",

                upidsIo: {
                    tabName: "Home",
                    tabRequired: true,
                    fieldOrder: 1.99,
                    tabIcon: "bi bi-three-dots",

                    label: "",
                    section: "multimedia",
                    swiperSlide: true,
                    sectionTitle: true,
                    subSection: "",
                    value: _.get(productData, `media.images.1024`, ''),
                    type: "mediaImage",
                }
            })
        }

        newMediaList.push({
            name: "productQrCode",
            label: "",
            placeholder: "",
            tooltipText: "",
            type: "",

            upidsIo: {
                tabName: "Home",
                tabRequired: true,
                fieldOrder: 1.99,
                tabIcon: "bi bi-three-dots",

                label: "",
                section: "multimedia",
                swiperSlide: true,
                sectionTitle: true,
                subSection: "",
                value: window.location.href.includes("localhost") ? `${AppConfig.b2cAppUrl}/${id}` : window.location.href,
                type: "qrCodeViewer",
            },
        })

        return newMediaList
    }

    const mediaItem = mediaList()


    return (
        <>
            <Swiper
                zoom={true}
                // autoHeight={true}
                pagination={{ clickable: true, }}
                navigation={true}
                modules={[Pagination, Navigation]}
                loop={true}
                onSlideChange={(e) => setSwiperIndex(e.realIndex)}
                className="bgWhite imageSliderSection fieldSwiperSliderSection"
            >
                <div
                    style={{
                        backgroundColor: "red",
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        width: "100%",
                        height: "100%",
                        paddingBottom: "-200px",
                    }}
                >
                    {
                        mediaItem && mediaItem[0] && mediaItem.map((item, index) => (
                            <React.Fragment key={index}>
                                {handleFieldListRecurssion(item)}
                            </React.Fragment>
                        ))
                    }
                </div>
            </Swiper>

        </>
    )
}   
