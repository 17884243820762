import React from 'react'
import { FaFacebook, FaInstagram, FaTwitter, FaGlobe } from 'react-icons/fa';



const SocialMediaComponent = (props) => {

    const getIcon = () => {
        if (props.title === 'Facebook')
            return <FaFacebook color="#000000" size={22} className="align-self-center" />
        else if (props.title === 'Twitter')
            return <FaTwitter color="#000000" size={22} className="align-self-center" />
        else if (props.title === 'Instagram')
            return <FaInstagram color="#000000" size={22} className="align-self-center" />
        else
            return <FaGlobe color="#000000" size={22} className="align-self-center" />
    }

    return (
        <>
            {getIcon()}
            <a target="_blank" href={props.url} className="attributeLinkValue ml-2">
                {props.title}
            </a>
        </>
    )
}

export default SocialMediaComponent