import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next';

const Dates = (props) => {
    const { value } = props
    const { t, i18n } = useTranslation('common');
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };



    return (
        <>
            <div className="row mt-20 attrDiv">

                {
                    value && value[0] && value.map((value, index) => {
                        return (
                            <div className="col-6">
                                <span className="attributeTitle">{t(`${value && value.label}`)} </span>
                                <span className="attributeValue">
                                   {
                                    value && value.date
                                   }
                                </span>
                            </div>
                        )
                    })
                }

            </div>
        </>
    )
}

export default Dates;