import React, { useState, useEffect } from 'react'
import { getAllergensAction } from 'src/redux/thunk/productThunk';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const Allergens = (props) => {
  const dispatch = useDispatch();
  const [allergenInfo, setAllergenInfo] = useState([]);
  const { t, i18n } = useTranslation('common');
    const _metadataByLocalLang = useSelector((state) => state.productSlice.metadataByLocalLang)


  const { name, label, value } = props

  useEffect(()=>{
    setAllergenInfo(_.get(_metadataByLocalLang, `${i18n.language}`, []))
  },[_metadataByLocalLang, i18n.language])

  const codeToText = (code) => {
    let text = code;
    allergenInfo.forEach((o) => {
      if (code == o.code && !!o.name[i18n.language])
        text = o.name[i18n.language];
    })
    return text;
  }

  const isValidAllergen = () => {
    let isValid = false;
    if (!!_.get(props, "allergens.list.contains") && _.get(props, "allergens.list.contains").length > 0)
      isValid = true;
    if (!!_.get(props, "allergens.list.may_contain") && _.get(props, "allergens.list.may_contain").length > 0)
      isValid = true;
    if (!!_.get(props, "allergens.list.free_from") && _.get(props, "allergens.list.free_from").length > 0)
      isValid = true;

    return isValid;

  }

  const isValidAddatives = () => {
    let isValid = false;
    if (!!_.get(props, "additives.contains") && _.get(props, "additives.contains").length > 0)
      isValid = true;
    if (!!_.get(props, "additives.may_contain") && _.get(props, "additives.may_contain").length > 0)
      isValid = true;
    if (!!_.get(props, "additives.free_from") && _.get(props, "additives.free_from").length > 0)
      isValid = true;

    return isValid;

  }

  const getAllergenseInfo = (allergensCode) => {
    const allergenseDataObj = allergenInfo.find((item) => item.code === allergensCode)
    let codeInfo = _.get(allergenseDataObj, `name[${i18n.language}]`, "")
    let fallbackCodeInfo = _.get(allergenseDataObj, `name[en]`, "")
    if (codeInfo) {
      return codeInfo
    }
    else if (fallbackCodeInfo) {
      return fallbackCodeInfo
    }
    else {
      return allergensCode
    }
  }

  return (
    <div>
      {/* <span className="allerg-adt-subtitle">{t('May Contains')}</span> */}
      <span className="allerg-adt-subtitle">{t(label)}</span>
      {value && value[0] && value.map((allergensCode, index) => (
        <span className="st-badge blue-badge" key={index}>
          {t(`${getAllergenseInfo(allergensCode)}`)}
        </span>
      ))}
    </div>
  )
}

export default Allergens;
