import React from 'react'

const UniqueIdentifier = (props) => {
    const {value, label} = props;
    
  return (
    <div className="row mt-10 attrDiv">
            <div className="col-12">
                <span className="attributeTitle">{label}</span>
                <span className="attributeValue">
                   {value && value}
                </span>
            </div>
        </div>
  )
}

export default UniqueIdentifier