import React, { useEffect } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error = () => {
  const navigate = useNavigate();
  const navigationType = useNavigationType()
  const {t, } = useTranslation('common');
  const location = useLocation();

  useEffect(() => {
    if (navigationType === "POP") {
      navigate("/")
    }
  }, [navigationType])
  

  return (
    <>
      <div id="">
        <div id="reportissue" className="footersection">
          <div className="footer">
            <div className="d-flex justify-content-center">
              <img
                src={require("src/assets/img/secondthought.png")}
                className="secondthoughtlogo"
              />
            </div>
            <div className="d-flex justify-content-center">
              <span className="m-20 title" style={{ fontSize: '1.75rem' }}>OOPS</span>
            </div>
            <div className=" justify-content-center">
            <h3 style={{ textAlign: "center",color:'tomato' }}>
                {t("Product id")} {location.state.productId}
              </h3>
              <p style={{ textAlign: "center" }}>
                {t("We could not find the product you were looking for. Please try it again.")}
              </p>
            </div>
            <div className="d-flex justify-content-center mt-20">
              <a className="policyLink" style={{ textAlign: "center" }} href="https://www.secondthought.fi/privacy-policy">
                Privacy policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error;
