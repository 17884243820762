if (typeof Storage === "undefined") {
  // Sorry! No Web Storage support...
}

/**
 * Check if key exists in local storage
 * @param  string key
 * @return boolean
 */
export function localStorageHas(key) {
  var item = localStorage.getItem(key);

  return item !== null;
}

/**
 * Retrive an object from local storage.
 * @param  string key
 * @return mixed
 */
 export function localStorageGet(key) {
  var item = localStorage.getItem(key);

  if (!item) return;

  if (item[0] === "{" || item[0] === "[") return JSON.parse(item);

  return item;
}

/**
 * Save some value to local storage.
 * @param string key
 * @param string value
 */
 export function localStorageSave(key, value) {
  if (value === undefined) console.info("Can't store undefinded value");

  if (typeof value === "object" || typeof value === "array") {
    value = JSON.stringify(value);
  }

  if (typeof value !== "string")
  console.info("Can't store unrecognized format value");

  localStorage.setItem(key, value);
}

/**
 * Remove element from local storage.
 * @param string key
 */
 export function localStorageRemove(key) {
  localStorage.removeItem(key);
}

export const Keys = {
  AUTH_STATE :"AUTH_STATE",
  DEVICE_ID : "DEVICE_ID",
  REFRESH_TOKEN : "REFRESH_TOKEN",
  I18LANG_CODE : "i18nextLng",
  LOGIN_EMAIL : "LOGIN_EMAIL",
}