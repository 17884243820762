import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { swiperHeightAction } from "src/redux/slice/productSlice";

const SCDescriptions = (props) => {
    const { name, label, value } = props
    const { t } = useTranslation('common');
    const dispatch = useDispatch()

    const [isReadMore, setIsReadMore] = useState(true);
    const [clickOntoggleReadMore, setclickOntoggleReadMore] = useState(false)
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
        setclickOntoggleReadMore(true)
    };


    useEffect(() => {
        if (clickOntoggleReadMore) {
            dispatch(swiperHeightAction())
        }
    }, [isReadMore])

    return (
        <div className="row mt-10 attrDiv">
            <div className="col-12">
                <span className="attributeTitle">{label}</span>
                <span className="attributeValue">
                    {(isReadMore && value.length > 150) ? `${value.slice(0, 150)}...` : value}
                    {(value.length > 150) &&
                        <span onClick={toggleReadMore} className="showMoreLess__read-or-hide">
                            {isReadMore ? t("read more") : t("show less")}
                        </span>
                    }
                </span>
            </div>
        </div>
    )
}

export default SCDescriptions