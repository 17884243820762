/**
 * App Feedback section
 */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getManufacturerPrivacyPolicyContent, sendFeedbackAction } from "src/redux/thunk/productThunk";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner'
import { MdCheckCircle } from 'react-icons/md';
import RatingButton from "src/components/form/RatingButton";
import PrivacyPolicyModal from "./PrivacyPolicyModal";


const Feedback = () => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { UPIDS, productData, PrivacyPolicyContent } = useSelector(state => state.productSlice)

  const [state, setState] = useState({
    npsScore: 0,
    feedbackText: "",
    email: "",
    publish: false,
    receiveNews: false,
    manufacturerPrivacyPolicy: false,
    accecptTermsAndCondition: false
  });

  const [isSending, setIsSending] = useState(false);
  const [isFeedbackDone, setIsFeedbackDone] = useState(false);
  const [manuPrivacyPolicyModalShow, setManuPrivacyPolicyModalShow] = useState(false)

  const companyNameTranslatedString = t('I agree <Manufacturer Name> privacy policy').replace('<Manufacturer Name>', `${productData?.companyName}`);

  useEffect(() => {
    if (productData?.contentItemUrl && productData?.privacyAddendum?.target?.value == true) {
      getPrivacyPolicyContentData();
    }
  }, [])

  const getPrivacyPolicyContentData = async () => {
    try {
      await dispatch(getManufacturerPrivacyPolicyContent(productData?.contentItemUrl)).unwrap();
    } catch (error) {
      console.log(error)
    }
  }

  const handleManufacturerPrivacyPolicyModalClick = () => {
    setManuPrivacyPolicyModalShow(true)
  }


  const handleInputChange = (e) => {
    if (e.target.type === 'checkbox') {
      setState({ ...state, [e.target.name]: e.target.checked });
    }
    else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  }


  const sendFeedback = async () => {
    // setIsFeedbackDone(true)
    let _id = !!UPIDS ? UPIDS : !!productData._2an ? productData._2an : productData.gtin
    if (_id.toString().length >= 14) {
      _id = _id.toString().substring(1);
    }

    let model = {
      email: state.email,
      id: !!UPIDS ? UPIDS : !!productData.gtin ? productData.gtin : productData._2an,
      productName: !!productData.productName && productData.productName.en,
      text: state.feedbackText,
      subscribeMarketing: state.receiveNews,
      feedbackToBusinessId: productData.businessIdentifiers,
      sourceUrl: window.location.href,
      npsScore: parseInt(state.npsScore),
      language: i18n.language,
      publish: state.publish,
      webhookUrl: productData?.webhookUrl,
      webhookKey: productData?.webhookKey,
      manufacturerPrivacyPolicy: state?.manufacturerPrivacyPolicy

    }
    setIsSending(true);
    try {
      const response = await dispatch(sendFeedbackAction(model)).unwrap()
      setIsSending(false);
      setIsFeedbackDone(true);
    } catch (error) {
      navigate("/error");
      console.info('🔴', error);
    }
  }

  const ratingArray = [
    { value: 1, color: "#ff0000" },
    { value: 2, color: "#ff1100" },
    { value: 3, color: "#ff2200" },
    { value: 4, color: "#ff5500" },
    { value: 5, color: "#ff7700" },
    { value: 6, color: "#ffaa00" },
    { value: 7, color: "#ffdd00" },
    { value: 8, color: "#CCff00" },
    { value: 9, color: "#44ff00" },
    { value: 10, color: "#00ff00" }
  ]



  return (
    <>
      <section id="feedback">
        <div className="titleDiv">
          <span className="productSubTitle text-uppercase">{t("Share feedback")}</span>
        </div>
        {!isFeedbackDone ? <div className="feedback-container show" >
          <div className="row pt-10">
            <div className="col-12">
              <textarea
                onChange={handleInputChange}
                type="textarea"
                name="feedbackText"
                className="feedbackText"
              ></textarea>
            </div>
          </div>

          <div className="col-12 mt-10">
            <div className="info col-12">
              {t("How likely would you recommend this product to your friends and family")}
            </div>
            <div className="feedbackRating">
              {/* <div className="feedbackRating__promotion">
              <div className="feedbackRating__promotion__detractors">{t("Detractors")}</div>
              <div className="feedbackRating__promotion__passive">{t("Passives")}</div>
              <div className="feedbackRating__promotion__promoters">{t("Promoters")}</div>
            </div> */}
              <div className="feedbackRating__score">
                {
                  ratingArray.map((item) => (
                    <RatingButton
                      key={item.value}
                      name="npsScore"
                      value={item.value}
                      color={item.color}
                      onClick={(e) => handleInputChange(e)}
                      selectedValue={state.npsScore}
                    />
                  ))
                }
              </div>
              <div className="feedbackRating__instruction">
                <div>{t("Not at all likely")}</div>
                <div>{t("Most likely")}</div>
              </div>
            </div>
          </div>

          <div className="row pt-10">
            <div className="col-12">
              <span className="email">
                {t("Email")}{(state.receiveNews || state.manufacturerPrivacyPolicy) ? <span className="subTitleRed"> ({t("Required")})</span> : <span className="subTitle"> ({t("Optional")})</span>}
              </span>
            </div>
            <div className="col-12">
              <input name="email" onChange={handleInputChange} type="email" className="emailBox"></input>
            </div>

          </div>
          <div className="row pt-10">
            <div className="col-12">
              <span className="info">
                {t("Required if you would like to receive response to your feedback.")}
              </span>
            </div>
          </div>
          <div className="row pt-10">
            <div className="col-12">
              <div style={{ display: "inline-flex" }}>
                <input name="receiveNews" onChange={handleInputChange} type="checkbox" className="checkbox"></input>
                <span className="info">
                  {t("I agree to receive Newsletter & Marketing Offers")}
                </span>
              </div>
            </div>
          </div>
          {productData?.privacyAddendum?.target?.value == true &&
            <div className="row pt-10">
              <div className="col-12">
                <div style={{ display: "inline-flex" }}>
                  <input name="manufacturerPrivacyPolicy" onChange={handleInputChange} type="checkbox" className="checkbox"></input>
                  <a className="info manufacturerPrivacyPolicyTextStyle" onClick={handleManufacturerPrivacyPolicyModalClick}>
                    {companyNameTranslatedString}
                  </a>
                </div>
              </div>
            </div>}
          <div className="row pt-10">
            <div className="col-12">
              <div style={{ display: "inline-flex" }}>
                <input name="publish" onChange={handleInputChange} type="checkbox" className="checkbox"></input>
                <span className="info">
                  {t("This feedback can be published")}
                </span>
              </div>
            </div>
          </div>

          <div className="row pt-10 lastItem">
            <div className="col-12">
              <button onClick={() => sendFeedback()} className="submit" disabled={(state.receiveNews || state.manufacturerPrivacyPolicy) ? ((state.receiveNews || state?.manufacturerPrivacyPolicy) && state.email) ? false : true : false}>{
                isSending ?
                  <TailSpin
                    color="#ffffff"
                    height={38}
                    width={38}
                    timeout={0} //3 secs
                  />
                  : t('Submit Feedback')
              }
              </button>
            </div>
          </div>
        </div>
          : <div className="d-flex flex-row mt-3"><MdCheckCircle className="mr-1" size={24} color="green" /><span className="nutrientTitle">{t("Feedback has been sent")}</span></div>
        }
      </section>

      {manuPrivacyPolicyModalShow &&
        <PrivacyPolicyModal
          showModal={manuPrivacyPolicyModalShow}
          PrivacyPolicyContent={PrivacyPolicyContent}
          setManuPrivacyPolicyModalShow={setManuPrivacyPolicyModalShow}
          stateData={state}
          setState={setState}
        />
      }
    </>
  );
};

export default Feedback;
