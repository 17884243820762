import React from 'react';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function DoormatRouterOverlayModal(props) {
    const { doormatRouterOverlayModel, productData, setDoormatRouterOverlayModel, goBack,handleOnlyShowNutritionalInfo,handleFullProfileClick } = props

    const { t } = useTranslation('common');

    return (
        <>
            <Modal size="md" centered={true} backdrop="static" show={doormatRouterOverlayModel} onHide={() => setDoormatRouterOverlayModel(false)} >
                <Modal.Header className="custom-modal-header" >
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                       <div className='d-flex mb-3 justify-content-center doormat-overlay-header-style'>
                        {t("whatIsYourInterest")}
                       </div>
                       <button className='doormat-labels-style d-flex justify-content-center align-items-center' onClick={handleOnlyShowNutritionalInfo}>

                        {t("onlyNutriInfo")}
                       </button>
                       <button className='doormat-labels-style doormat-labels-style1 mt-2 d-flex align-items-center justify-content-center' 
                       onClick={handleFullProfileClick}
                       >
                          {t("IwantFullProfile")}
                       </button>
                </Modal.Body>
                <Modal.Footer className="custom-modal-header" style={{ justifyContent: 'center' }}>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default DoormatRouterOverlayModal