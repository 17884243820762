/**
 * Basic details of the product
 */
import React, { useRef, useLayoutEffect } from "react";
import { hexToRGB } from "src/helper/HelperFunctions";
import _ from 'lodash';

const Banner = (props) => {
    const {value, bgColor} = props
    const bannerStyleRef = useRef(null);


    useLayoutEffect(() => {
        if (bgColor) {
            bannerStyleRef.current.style.setProperty("background-color", `${hexToRGB(bgColor, 0.6)}`, "important");
        }
    }, [bgColor])

    return (
        <section className="banner" ref={bannerStyleRef}>
            <div className="row">
                <span className="banner__Title">
                    {value}
                </span>
            </div>
        </section>
    )
}

export default Banner;
