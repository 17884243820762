import React, { Fragment } from 'react'

function LayoutWrapper(props) {



    return (
        <Fragment>
            {/* <main className="layoutWrapper__mainContainer"> */}
                {props.children}
            {/* </main> */}
        </Fragment>
    )
}

export default LayoutWrapper