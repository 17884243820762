import React, { useState, useEffect, useRef } from 'react'
import _ from "lodash";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { handleStopVideo } from 'src/helper/HelperFunctions';
import SwipeLeftIconComponent from '../SwipeLeftIconComponent';
import FieldSchemaWrapper from './FieldSchemaWrapper';
import Footer from '../footer';

const SchemaRender = (props) => {
  const { upidsIoProductTabStructuredSchema } = props

  const params = new URLSearchParams(window.location.search);
  const isMobileDevice = params.get("mobile");

  const { t, i18n } = useTranslation('common');
  const sliderRef = useRef(null);
  const { productData, productUpidsData, swiperHeightEventNumber,isNutritionalDataShownFlagValue } = useSelector((state) => state.productSlice)

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(1)
  const [showSwipeLeftIcon, setShowSwipeLeftIcon] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setShowSwipeLeftIcon(false)
    }, 10000);

    /** To resolve the certifications logo images issue we have done that ---------------------------- */
      setSwiperIndex(1)
      if (_.has(sliderRef, 'current.swiper', false)) {
        sliderRef.current.swiper.slideTo(1 + 1); // index+1
      }

      setTimeout(() => {
        setSwiperIndex(0)
        if (_.has(sliderRef, 'current.swiper', false)) {
          sliderRef.current.swiper.slideTo(0 + 1); // index+1
        } 
      }, 2000);

      /** --------------------------------------------------------------------------------------------- */

  }, [])

  useEffect(() => {
    handleStopVideo()
  }, [swiperIndex])


  useEffect(() => {
    if (_.has(sliderRef, 'current.swiper', false)) {
      sliderRef.current.swiper.slideTo(0 + 1); // index+1
    }
  }, [i18n.language])


  useEffect(() => {
    handleUpdateHeightAuto()
    return () => {
    }
  }, [swiperHeightEventNumber])
  
  useEffect(() => {
    if(isNutritionalDataShownFlagValue){
      let findObjIndex = _.findIndex(sortListedTabStructuredSchema || [],(d)=> d.tabName=="Nutrition")
      setSwiperIndex(findObjIndex)
      if (_.has(sliderRef, 'current.swiper', false)) {
        sliderRef.current.swiper.slideTo(findObjIndex + 1); // index+1
      }
    }
    }, [isNutritionalDataShownFlagValue])


  const handleTabChange = (e) => {
    window.scroll(0, 0)
    setSwiperIndex(e.realIndex)
  }

  const handleUpdateHeightAuto = () => {
    if (_.has(sliderRef, 'current.swiper', false)) {
      sliderRef.current.swiper.updateAutoHeight(10)
    }
  }

  const getSelectedFieldLanguage = () => {
    if (i18n && i18n.language) {
      return i18n.language
    }
    else {
      return "en"
    }
  }

  const selectedFieldLanguage = getSelectedFieldLanguage()


  let bottomTabBackgroundColor = ""
  if (_.get(productData, 'marketing.custom.bgColor')) {
    bottomTabBackgroundColor = productData.marketing.custom.bgColor
  }

  const getProductSortListedFieldWithConditional = (upidsIoProductTabStructuredSchema) => {
    const handleFieldListRecurssionItem = (field, parentObject) => {
      if (field && field.fieldList && field.fieldList[0]) {
        const newFieldObject = {
          ...field,
          fieldList: [],
        }

        for (const fieldItem of field.fieldList) {
          const fieldHasData = handleFieldListRecurssionItem(fieldItem, field)
          if (fieldHasData) {
            newFieldObject.fieldList.push(fieldHasData)
          }
        }

        if (newFieldObject && newFieldObject.tabRequired) {
          return newFieldObject
        }
        else if (newFieldObject && newFieldObject.sectionRequired) {
          return newFieldObject
        }
        else if (newFieldObject && newFieldObject.fieldList && newFieldObject.fieldList[0]) {
          return newFieldObject
        }
      }
      else {
        if (_.get(field, "upidsIo.fieldRequired", false)) {
          return field
        }

        let dataValue
        const valueFrom = _.get(field, "upidsIo.valueFrom", "masterProduct")
        if (valueFrom === "upidsProduct") {
          if (field.isMultilingual && !(field.type === "select" || field.type === "multiSelectWithModal")) {
            dataValue = _.get(productUpidsData, `${field.nameUpdated}.${selectedFieldLanguage}`, "")
            if (!dataValue) {
              const fallbackLanguage = _.get(productUpidsData, `field.fallbackLanguage`, "")
              dataValue = fallbackLanguage ? _.get(productUpidsData, `${field.nameUpdated}.${fallbackLanguage}`, "") : ""
            }
          }
          else {
            dataValue = _.get(productUpidsData, `${field.nameUpdated}`, "")
          }

        }
        else if (valueFrom === "masterProduct") {
          if (field.isMultilingual && !(field.type === "select" || field.type === "multiSelectWithModal")) {
            dataValue = _.get(productData, `${field.nameUpdated}.${selectedFieldLanguage}`, "")
            if (!dataValue) {
              const fallbackLanguage = _.get(productData, `field.fallbackLanguage`, "")
              dataValue = fallbackLanguage ? _.get(productData, `${field.nameUpdated}.${fallbackLanguage}`, "") : ""
            }
          }
          else {
            dataValue = _.get(productData, `${field.nameUpdated}`, "")
          }
        }

        if (dataValue) {
          const isValueObjectOrArray = (_.isObject(dataValue) || _.isArray(dataValue)) ? true : false
          if (isValueObjectOrArray && !_.isEmpty(dataValue)) {
            return field
          }
          else if (!isValueObjectOrArray) {
            return field;
          }
        }

        return
      }

    }


    const schema = []
    upidsIoProductTabStructuredSchema && upidsIoProductTabStructuredSchema[0] && upidsIoProductTabStructuredSchema.forEach((item) => {
      const tabRenderData = handleFieldListRecurssionItem(item, { itemType: "tab" })
      if (tabRenderData) {
        schema.push(tabRenderData)
      }
    })

    return schema
  }

  const sortListedTabStructuredSchema = getProductSortListedFieldWithConditional(upidsIoProductTabStructuredSchema)

  return (
    <>
      <Swiper
        resizeObserver={true}
        autoHeight={true}
        loop={true}
        spaceBetween={10}
        // pagination={{ clickable: true, }}
        ref={sliderRef}
        onSlideChange={(e) => handleTabChange(e)}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="productScreen"
        onClick={() => handleUpdateHeightAuto()}
      >
        {
          sortListedTabStructuredSchema && sortListedTabStructuredSchema[0] && sortListedTabStructuredSchema.map((item, index) => (
            <SwiperSlide key={index} className="product__item">
              <FieldSchemaWrapper
                key={`field-schema-wrapper-${index}`}
                tabInfo={item}
                productData={productData}
                productUpidsData={productUpidsData}
                selectedFieldLanguage={selectedFieldLanguage}
              />
              <Footer />
            </SwiperSlide>
          ))
        }
      </Swiper>

      {showSwipeLeftIcon && <SwipeLeftIconComponent />}


      {isMobileDevice !== "1" &&
        <Swiper
          onSwiper={setThumbsSwiper}
          // loop={true}
          spaceBetween={1}
          slidesPerView={15}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="bottomMenu"
          style={{ backgroundColor: `${bottomTabBackgroundColor}` }}
        >
          {sortListedTabStructuredSchema && sortListedTabStructuredSchema[0] && sortListedTabStructuredSchema.map((item, index) => (
            <SwiperSlide
              key={index}
              className='bottomMenu__item'
              data-enabled={swiperIndex === index}
            >
              <i className={item.tabIcon} />
              <div>{t(item.tabName)}</div>
            </SwiperSlide>
          ))}
        </Swiper>
      }

    </>
  )
}

export default SchemaRender