import React from 'react'
// import ReactPlayer from 'react-player'

function VideoViewer(props) {

    const { value, } = props

    const getVideoUrl = () => {
        const url = value
        if (url.includes("youtube")) {
            const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            const match = url.match(regExp);
            const youtubeVideoId = (match && match[7].length == 11) ? match[7] : false;
            const youtubeVideoUrl = `https://www.youtube-nocookie.com/embed/${youtubeVideoId}?controls=1&autoplay=0&version=3&enablejsapi=1`
            return { videoUrl: youtubeVideoUrl, videoType: "youtube" };
        } else {
            return { videoUrl: url, videoType: "other" };
        }
    }

    const { videoUrl, videoType } = getVideoUrl()

    return (
        <div className="imageSliderSection__item">
            {
                videoType === "youtube" ?
                    <iframe
                        className='youTubeVideoIframe'
                        id="youTubeVideoIframe"
                        width="100%"
                        height="100%"
                        src={videoUrl}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        allowFullScreen
                    />
                    :
                    <>
                        <video
                            width="90%"
                            controls="controls"
                            preload="metadata"
                            className='otherVideoElement'
                        >
                            <source src={`${videoUrl}#t=0.5`} type="video/mp4" />
                        </video>
                        {/* <iframe
                            className='youTubeVideoIframe'
                            id="youTubeVideoIframe"
                            width="100%"
                            height="100%"
                            src={videoUrl}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            allowFullScreen
                        /> */}
                    </>
            }
        </div>
    );
};

export default VideoViewer;