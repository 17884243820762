export const settings = {
    companyLogoUrl : '',
    footerImageUrl:'',
    footerText : `© 2020 Second Thought Ltd. Information presented on this page is originating from external source systems, Second Thought Ltd cannot guarantee the quality of the data but does best to monitor it.`,
    headerColor:'#'
}

export const certificationsUrl = {
    msc:"https://www.msc.org/en-au",
    utz:"https://www.rainforest-alliance.org/utz/",
    reilukauppa:"https://reilukauppa.fi/",
    rainforestalliance:"https://www.rainforest-alliance.org/"
}

export const templateJson= {

    fields:[
        {
            section:'basicDetail',
            fieldName:'productName'
        },
        {
            section:'basicDetail',
            fieldName:'brandName'
        },
        {
            section:'basicDetail',
            fieldName:'descriptionShort'
        },
        {
            section:'basicDetail',
            fieldName:'description'
        },
        {
            section:'basicDetail',
            fieldName:'modifiedDate'
        },
        {
            section:'basicDetail',
            fieldName:'productExpires'
        },
        {
            section:'basicDetail',
            fieldName:'UPIDS'
        },
        {
            section:'basicDetail',
            fieldName:'gtin'
        },
        {
            section:'basicDetail',
            fieldName:'claim'
        },
        {
            section:'ingredients',
            fieldName:'ingredients'
        },
        {
            section:'allergenAddatives',
            fieldName:'allergens'
        },
        {
            section:'allergenAddatives',
            fieldName:'additives'
        },
        {
            section:'allergenAddatives',
            fieldName:'allergenAddatives'
        },
        {
            section:'nutrition',
            fieldName:'nutrition'
        },
        {
            section:'feedback',
            fieldName:'feedback'
        },
        {
            section:'marketingDetail',
            fieldName:'websiteUrl'
        },
        {
            section:'marketingDetail',
            fieldName:'publicEmail'
        },
        {
            section:'marketingDetail',
            fieldName:'publicTelephone'
        },
        {
            section:'marketingDetail',
            fieldName:'buyNowUrls'
        },
        {
            section:'marketingDetail',
            fieldName:'socialMediaUrls'
        },
        {
            section:'marketingDetail',
            fieldName:'externalLinks'
        },
        {
            section:'marketingDetail',
            fieldName:'postalAddress'
        },
        {
            section:'marketingDetail',
            fieldName:'otherFiles'
        },
        {
            section:'marketingDetail',
            fieldName:'marketingTexts'
        },
        {
            section:'marketingDetail',
            fieldName:'usageInstructions'
        },
        {
            section:'carbonFootprintDetails',
            fieldName:'total'
        },
        {
            section:'carbonFootprintDetails',
            fieldName:'dateOfCalculation'
        },
        {
            section:'carbonFootprintDetails',
            fieldName:'source'
        },
        {
            section:'carbonFootprintDetails',
            fieldName:'externalReference'
        },

    ]
}

