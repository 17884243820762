/**
 * Basic details of the product
 */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { retriveLinkedProductDataWithGTINAction, retriveLinkedProductDataWithUPIDSAction } from "src/redux/thunk/productThunk";
import { useTranslation } from 'react-i18next';
import { DetectUPIDSType } from '../helper/HelperFunctions';
import { useLocation } from "react-router-dom";
import GrainpassData from '../pages/views/food/components/GrainpassData ';

import _ from "lodash";
import { AppConfig } from "src/helper/AppConfig";

const LinkedUpidsData = (props) => {
  const { linkUpids } = props

  let _path = useLocation().pathname;
  let _baseUrl = window.location.href.replace(_path, '');
  const { t } = useTranslation('common');
  const dispatch = useDispatch()

  const [state, setState] = useState({
    productData: null,
    UPIDS: linkUpids,
    locale: "en",
    isUpidsProduct: DetectUPIDSType(linkUpids),
    error: false
  });

  useEffect(() => {
    getUpidsData()
  }, []);

  const getUpidsData = async () => {
    if (!!state.UPIDS && state.UPIDS !== "") {
      const params = new URLSearchParams(window.location.search);
      const locale = params.get("lang");
      setState({ ...state, })
      if (state.isUpidsProduct) {
        try {
          const response = await dispatch(retriveLinkedProductDataWithUPIDSAction(state.UPIDS)).unwrap()
          getDataWithGtin(response, state.UPIDS, locale);
        } catch (error) {
          setState({ ...state, error: true })
          console.error("EXCEPTION: ", error)
        }
      } else {
        getDataWithGtin({ gtin: state.UPIDS })
      }
    }
  }

  const getDataWithGtin = async (upids_data, UPIDS, locale) => {
    try {
      let gtin = (upids_data.gtin == undefined || upids_data.gtin == "") ? upids_data.articleIdentifier : upids_data.gtin;
      if (gtin.toString().length <= 13) {
        gtin = '0' + gtin.toString();
      }
      const response = await dispatch(retriveLinkedProductDataWithGTINAction(gtin)).unwrap()
      let _newData = { ...upids_data, ...response };
      setState({
        ...state,
        productData: _newData,
        UPIDS: UPIDS,
        locale: !!locale ? locale : "en",
      });

    } catch (error) {
      setState({ ...state, error: true })
      console.info('🔴', error);
    }
  };


  return (
    <>
      <section className="linked-section" id="feedback" >
        {
          state.error ?
            <>
              <div className="p-1">
                {t("Linked product was not found or accessible.")}
              </div>
            </> :
            <>
              <div className="row mt-10 attrDiv pt-3">
                <div className="col-12">
                  <span className="attributeTitle">{t('Product name')}</span>
                  <span className="attributeValue">
                    {!!_.get(state, "productData.productName") ? state.productData.productName[props.locale] : ""}
                  </span>
                </div>
              </div>
              <div className="row mt-10 attrDiv">
                <div className="col-12">
                  <span className="attributeTitle">{t('Company')}</span>
                  <span className="attributeValue">
                    {!!_.get(state, "productData.brandName") ? state.productData.brandName : ""}
                  </span>
                </div>
              </div>
              {!!_.get(state, "productData.descriptionShort") && <div className="row mt-10 attrDiv">
                <div className="col-12">
                  <span className="attributeTitle">{t('Description Short')}</span>
                  <span className="attributeValue">
                    {state.productData && state.productData.descriptionShort ? state.productData.descriptionShort[props.locale] : ""}
                  </span>
                </div>
              </div>}
              {(!!_.get(state, `productData.description.${props.locale}`) && _.get(state, `productData.description.${props.locale}`) !== "") &&
                <div className="row mt-10 attrDiv">
                  <div className="col-12">
                    <span className="attributeTitle">{t('Description')}</span>
                    <span className="attributeValue">
                      {state.productData && state.productData.description ? state.productData.description[props.locale] : ""}
                    </span>
                  </div>
                </div>}
              {!!_.get(state, "productData.cfpBatchId") && <div className="row mt-10 attrDiv">
                <div className="col-12">
                  <span className="attributeTitle">{t('CFP batch Id')}</span>
                  <span className="attributeValue">
                    {state.productData && state.productData.cfpBatchId ? state.productData.cfpBatchId : ""}
                  </span>
                </div>
              </div>}
              <GrainpassData isEmbed grainpassData={_.get(state.productData, 'grainpassData')} />
              <div className="view-more-text-container">
                <a href={AppConfig.b2cAppUrl + "/" + linkUpids} target="_blank">{t("View details")}</a>
              </div>
            </>
        }
      </section>

    </>
  );
};

export default LinkedUpidsData;
