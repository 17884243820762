import React from 'react'
import Modal from "react-bootstrap/Modal";
import { Zoom, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import { useTranslation } from 'react-i18next';

function ImageModal(props) {
    const { isShowModal, imageUrl, modalClose, } = props
    // const { t, i18n } = useTranslation('common');

    return (
        <>
            <Modal
                size="xlg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
                backdrop="static"
                show={isShowModal}
                onHide={modalClose}
                className="imageModal"
            // fullscreen={true}
            >
                <Modal.Header closeButton={modalClose}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                    <div className="row m-0 justify-content-center imageSliderSection" style={{ margin: "0px", padding: "0px" }}>
                        <Swiper
                            zoom={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Zoom, Navigation, Pagination]}
                            className="imageModalSwiper"
                        >
                            <SwiperSlide >
                                <div className="swiper-zoom-container" >
                                    <img src={imageUrl} alt="" className="sliderImg" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ImageModal