import React from 'react'
import { useTranslation } from 'react-i18next';
import { MdLink, MdLocalMall } from 'react-icons/md';
import SocialMediaComponent from '../components/SocialMediaComponent';

const ExternalLinks = (props) => {
    const { t, i18n } = useTranslation('common');
    const { field, name, label, value } = props

    const getExternalLinkIcon = (value, name) => {
        if (value && name === 'buyNowUrls') {
            return (
                <>
                    <MdLocalMall color="#000000" size={22} className="align-self-center" />
                    <a target="_blank" href={value.url} className="attributeLinkValue ml-2">
                        {value.title}
                    </a>
                </>
            )
        } else if (value && name === 'socialMediaUrls') {
            return <SocialMediaComponent title={value.title} url={value.url} />
        } else if (value && name === 'externalLinks') {
            return (
                <>
                    <MdLink color="#000000" size={22} className="align-self-center" />
                    <a target="_blank" href={value.url} className="attributeLinkValue ml-2">
                        {value.title}
                    </a>
                </>
            )
        }
    }
    return (
        <div className="row mt-10 attrDiv">
            <div className="col-12">
                <span className="attributeTitle">{label}</span>
                <div className="row">
                    {value && value[0] && value.map((o, index) => (
                        <div key={index} style={{ padding: 5, border: '1px solid #000000', borderRadius: 20, marginRight: 6, cursor: 'pointer', marginTop: 4, marginBottom: 8, maxWidth: 'max-content' }} className="col-sm-12 col-md-6">
                            {getExternalLinkIcon(o, name)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
        // </div>
    )
}

export default ExternalLinks