import React from 'react'
import { MdLink } from 'react-icons/md'
import { useTranslation } from 'react-i18next';

const Links = (props) => {
  const { value } = props;
  const { t, i18n } = useTranslation('common');

  return (
    <div className='row mt-10 attrDiv'>
      <div className="col-12">
        <div className="d-flex align-item-center">
          <MdLink color="#000000" size={22} className="d-flex align-self-center" />
          <a target="_blank" href={value && value} className="attributeLinkValue ml-2">
            {t("Visit website")}
          </a>
        </div>
        {/* <span className="attributeTitle">{t('Telephone')}</span> */}

      </div>
    </div>
  )
}

export default Links