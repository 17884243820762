import React from 'react'
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
const Nutritional = (props) => {
    const { value, field, name, label } = props
    const { t, i18n } = useTranslation('common');

    return (
        <div>
            <div className="titleDiv">
                <span className="productSubTitle">
                    {t("Nutritional information is calculated for 100 g of prepared product")}
                </span>
            </div>
            {value && value[0] && value.map((detail, index) => (
                <div className="row pt-10 attrDiv" key={index}>
                    <div className="col-6 textLeft">
                        {!!detail.nutrientName.en && <span className="nutrientTitle">{t(_.get(detail, 'typeCode'))}</span>}
                    </div>
                    <div className="col-6 textRight">

                        {<span className="nutrientValue">
                            {!!detail.quantityUnit && Number(detail.quantity).toFixed(2) + " " + t(detail.quantityUnit.toLowerCase())}
                        </span>}
                    </div>
                </div>
            ))
            }
        </div>
    )
}

export default Nutritional