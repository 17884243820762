import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MdClose } from 'react-icons/md';
import _ from "lodash"
import msc from '../../assets/img/certifications/msc_certificate_logo.jpeg';
import utz from '../../assets/img/certifications/utz_certificate_logo.jpeg';
import rainforestalliance from '../../assets/img/certifications/rainforestAlliance_certificate_logo.jpeg';
import nutriscore_a from "../../assets/nutriscore/nutriscore-a.svg"
import nutriscore_b from "../../assets/nutriscore/nutriscore-b.svg"
import nutriscore_c from "../../assets/nutriscore/nutriscore-c.svg"
import nutriscore_d from "../../assets/nutriscore/nutriscore-d.svg"
import nutriscore_e from "../../assets/nutriscore/nutriscore-e.svg"
import { useSelector } from "react-redux";
import fin from "../../assets/flag/fin.svg"



const ExternalSources = () => {


    const { t } = useTranslation('common');

    const [showCertificationModal, setShowCertificationModal] = useState(false)

    const { productDataFromExternalSource, productData } = useSelector((state) => state.productSlice)

    const getCertificateLogo = (data) => {
        switch (data) {
            case "msc":
                return <img src={msc} alt="" height={45} width={45}></img>
            case "utz":
                return <img src={utz} alt="" height={45} width={45}></img>
            case "rainforestalliance":
                return <img src={rainforestalliance} alt="" height={45} width={45}></img>
            case "a":
                return <img src={nutriscore_a} alt="" height={45} width={100}></img>
            case "b":
                return <img src={nutriscore_b} alt="" height={45} width={100}></img>
            case "c":
                return <img src={nutriscore_c} alt="" height={45} width={100}></img>
            case "d":
                return <img src={nutriscore_d} alt="" height={45} width={100}></img>
            case "e":
                return <img src={nutriscore_e} alt="" height={45} width={100}></img>
            default:
                break;
        }
    }

    return (
        <>
            <>
                {/* If product data contains externalSources[0].name = off, fetch the resource and check if the data contains “nutriscore_grade”. */}
                {!!productData.externalSources &&
                    <>
                        {'enabled' in productData.externalSources[0] ? productData.externalSources[0].enabled &&
                            <span className="col-12" role={'button'} onClick={() => setShowCertificationModal(true)}>
                                {getCertificateLogo(_.get(productDataFromExternalSource, "product.nutriscore_grade"))}
                            </span> :
                            productData.externalSources[0].name == "off" &&
                            <span className="col-12" role={'button'} onClick={() => setShowCertificationModal(true)}>
                                {getCertificateLogo(_.get(productDataFromExternalSource, "product.nutriscore_grade"))}
                            </span>
                        }
                    </>
                }
            </>
            {
                productData.externalSources && productData.externalSources[0] && productData.externalSources[0].enabled &&
                <div className="col-2 d-flex justify-content-center align-items-center">
                    <img src={fin} alt="" height={35} width={45}></img>
                </div>
            }

            <Modal size="lg" centered={true} backdrop="static" show={showCertificationModal} onHide={() => setShowCertificationModal(false)} >
                <Modal.Header style={{ justifyContent: 'end' }}>
                    <span className="close-text" onClick={() => setShowCertificationModal(false)} >
                        <MdClose style={{ marginBottom: 2, color: 'black' }} fontSize={24} fontWeight={'bold'} />
                        {t('Close')}
                    </span>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                    <div className="row m-0 justify-content-center">
                        <div className="form-group col-12 m-2"  >
                            <span>{t("NutriScore information")}</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <Button
                        className="confirm_button"
                        variant="primary"
                        type="button"
                        onClick={() => setShowCertificationModal(false)}
                    >{t('Close')}</Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ExternalSources