import React from 'react'
import Modal from "react-bootstrap/Modal";
import D3Tree from './D3Tree'

function D3TreeModal(props) {
    const { isShowModal, allLinkedUpidsIdData, modalClose, } = props

    return (
        <>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
                backdrop="static"
                show={isShowModal}
                onHide={modalClose}
                className="d3TreeModal"
                // fullscreen={true}
            >
                <Modal.Header closeButton={modalClose}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal', height: "90vh" }}>
                    <div className="row m-0 justify-content-center imageSliderSection" style={{ margin: "0px", padding: "0px" }}>
                        <div className="swiper-zoom-container" >
                            <D3Tree allLinkedUpidsIdData={allLinkedUpidsIdData} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default D3TreeModal