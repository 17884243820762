import React from "react";
import { MdClose } from "react-icons/md";

StCloseButton.defaultProps = {
  onClick: () => {
    alert("This is on click");
  },
};

export function StCloseButton({ onClick, text }) {
  return (
    <div onClick={onClick}>
      <MdClose
        style={{
          color: "black",
          marginBottom: "2px",
          fontSize: 24,
          cursor: "pointer",
        }}
      ></MdClose>
      <span
        style={{
          color: " #d1334c",
          textTransform: "uppercase",
          fontWeight: "bold",
          fontSize: "14px",
          // fontFamily: "proxima-nova",
          cursor: "pointer",
        }}
      >
        {text}
      </span>
    </div>
  );
}
