import { configureStore } from "@reduxjs/toolkit";
import commonSlice from "./slice/commonSlice";
import productSlice from "./slice/productSlice";


export const store = configureStore({
    reducer: {
        commonSlice: commonSlice.reducer,
        productSlice: productSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

