import { createSlice } from "@reduxjs/toolkit";
import {
    getInfoDataAction, getUserProfileAction, loginAction, requestLoginLinkAction,
    checkProductOwner, changeProductOwner, getPrivacyPolicyDataAction
} from "../thunk/commonThunk";

const initialState = {
    isLoading: false,
    isPriavacyLoading: false,
    info: null,
};

const commonSlice = createSlice({
    name: "commonSlice",
    initialState: initialState,
    reducers: {
        disableOwnership: (state, action) => {
            return {
                ...state,
                productOwner: {
                    ...state.productOwner,
                    owner: false,
                }
            };
        },
    },
    extraReducers:
        (builder) => {
            builder
                /** RETRIEVE INFO*/
                .addCase(getInfoDataAction.pending, (state, action) => {
                    state.isLoading = true;
                })
                .addCase(getInfoDataAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.info = action.payload
                })
                .addCase(getInfoDataAction.rejected, (state, action) => {
                    state.isLoading = false;
                })

                .addCase(requestLoginLinkAction.pending, (state, action) => {
                    state.isLoading = true;
                })
                .addCase(requestLoginLinkAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.emailResponse = action.payload;
                })
                .addCase(requestLoginLinkAction.rejected, (state, action) => {
                    state.isLoading = false;
                })

                .addCase(loginAction.pending, (state, action) => {
                    state.isLoading = true;
                })
                .addCase(loginAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.login = action.payload;
                })
                .addCase(loginAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.error = action.payload;
                })

                .addCase(getUserProfileAction.pending, (state, action) => {
                    state.isLoading = true;
                })
                .addCase(getUserProfileAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.user = action.payload;
                })
                .addCase(getUserProfileAction.rejected, (state, action) => {
                    state.isLoading = false;
                })

                .addCase(checkProductOwner.pending, (state, action) => {
                    state.isLoading = true;
                })
                .addCase(checkProductOwner.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.productOwner = action.payload;
                })
                .addCase(checkProductOwner.rejected, (state, action) => {
                    state.isLoading = false;
                })

                .addCase(changeProductOwner.pending, (state, action) => {
                    state.isLoading = true;
                })
                .addCase(changeProductOwner.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.changeProductOwner = action.payload;
                })
                .addCase(changeProductOwner.rejected, (state, action) => {
                    state.isLoading = false;
                })


                .addCase(getPrivacyPolicyDataAction.pending, (state, action) => {
                    state.isPriavacyLoading = true
                })
                .addCase(getPrivacyPolicyDataAction.fulfilled, (state, action) => {
                    state.isPriavacyLoading = false
                })
                .addCase(getPrivacyPolicyDataAction.rejected, (state, action) => {
                    state.isPriavacyLoading = false
                })


        },
});

export const { disableOwnership } = commonSlice.actions;
export default commonSlice;
