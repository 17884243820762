/**
 * Basic details of the product
 */
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import msc from "src/assets/img/certifications/msc_certificate_logo.jpeg"
import utz from "src/assets/img/certifications/utz_certificate_logo.jpeg"
import rainforestalliance from "src/assets/img/certifications/rainforestAlliance_certificate_logo.jpeg"
import { certificationsUrl } from '../../template/template';
import { useDispatch, useSelector } from "react-redux";


const Certifications = (props) => {
    const dispatch = useDispatch()
    const { value } = props
    const commonMetaDataState = useSelector((state) => state?.productSlice?.commonMetaData);
    const { certifications } = commonMetaDataState

    const { t } = useTranslation('common');

    function isObject(value) {
        return Object.prototype.toString.call(value) === '[object Object]';
    }

    const getCertificateLogo = (data) => {
        if (!isObject(data)) {
            switch (data) {
                case "msc":
                    return <img src={msc} alt="" height={70} width={200} />;
                case "utz":
                    return <img src={utz} alt="" height={150} width={200} />;
                case "rainforestalliance":
                    return <img src={rainforestalliance} alt="" height={150} width={200} />;
                default:
                    break;
            }
        } else {
            let filterById = certifications?.filter((ele) => ele.id == data.value).pop();
            if (filterById?.image_url) {
                return <img src={filterById?.image_url} alt="certification" height={150} />
            }
        }
    }

    const getCertificateUrl = (certId) => {
        let filterById = certifications?.filter((ele) => ele.id == certId?.value).pop();
        return filterById?.url || ""
    }

    const getCertificateName = (certId) => {
        let filterById = certifications?.filter((ele) => ele.id == certId?.value).pop();
        return filterById?.name || ""
    }

    return (
        <div className="mt-1">
            {!!value && value.map((data, i) => (
                // not to show MSC certification information even the product data contains MSC reference
                data !== "msc" &&
                <div key={i} className="col-12 pt-2 mt-1">
                    {getCertificateLogo(data)}
                    <span className="attributeValue mt-1">
                        {!isObject(data) ? t(`${data}`) : getCertificateName(data)}
                    </span>
                    <div className="attributeValue mt-1">
                        {
                            !isObject(data) ?
                                <a href={certificationsUrl[`${data}`]} target={'_blank'} style={{ textDecoration: 'underline' }}>{certificationsUrl[`${data}`]}</a>
                                :
                                <a href={`${getCertificateUrl(data)}`} target={'_blank'} style={{ textDecoration: 'underline' }}>{`${getCertificateUrl(data)}`}</a>

                        }
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Certifications;
