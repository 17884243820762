import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useNavigate, useSearchParams } from "react-router-dom";
import { loginAction } from "src/redux/thunk/commonThunk";
import { localStorageGet, localStorageSave, Keys } from "src/helper/localStorageHelper";
import { usePrevious } from "src/helper/CustomHook";
import Cookies from 'universal-cookie';
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const _state = useSelector((state) => state.commonSlice.login, shallowEqual);
  const loginError = useSelector((state) => state.commonSlice.error, shallowEqual);
  const prevPropsState = usePrevious({ _state });
  const cookies = new Cookies();

  let date = new Date();
  date.setDate(date.getDate() + 30);

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    let model = {
      email: localStorageGet(Keys.LOGIN_EMAIL),
      code: searchParams.get("code"),
    }

    await dispatch(loginAction(model)).unwrap()
  }

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.response !== _state && _state) {
        localStorageSave(
          Keys.AUTH_STATE,
          JSON.stringify(_state)
        );

        cookies.set('AUTH_STATE', JSON.stringify(_state), { path: '/', expires: date });

        // postMessage for v2 mobile app
        try {
          window.mobileapplogin.postMessage(_state.token);
        } catch (error) {
        }

        let cookieGet = cookies.get('CURRENT_PATH');
          window.location.href = cookieGet;
          navigate("/:id/*",{replace: true});
        // navigate("/")
      }
    }
  }, [_state]);

  return (
    <>
      <div id="">


      {!!loginError ?
         <div className="mt-5">
          <p>{t("Login was unsuccessful")}</p>
          <p>{t("Possible reasons for failure")}</p>
          <ul>
            <li>{t("Token is too old")}</li>
            <li>{t("Token is invalid")}</li>
          </ul>
          <a href="https://dev.upids.io/" style={{color:'blue'}}>{t("Click here")}</a><span> {t("to redirect to upids.io")}</span>
        </div> :
          <div  className="mt-5">{t("Logging you in")}</div>
        }
      </div>
    </>
  );
}

export default Login;
