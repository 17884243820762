import React, { createContext, useState } from "react";
import Cookies from "universal-cookie";
import { jwtDecode } from "jwt-decode";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const cookies = new Cookies();
  let _authState = cookies.get("AUTH_STATE");
  const [authState, setAuthState] = useState(!!_authState ? jwtDecode(_authState.token) : {authState:{level:1}})
  const value = { authState, setAuthState };


  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  )

}

export default AppContextProvider;