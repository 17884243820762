import React from 'react';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { StCloseButton } from './StCloseButton';

function PrivacyPolicyModal(props) {

    const { t } = useTranslation('common');

    const handleOKClick = () => {
        props.setManuPrivacyPolicyModalShow(false)
    }


    return (
        <>
            <Modal size="lg" centered={true} show={props.showModal} style={{ zIndex: 9999999999 }} onHide={() => props.setManuPrivacyPolicyModalShow(false)} >
                <Modal.Header >
                <div style={{ position: "absolute", right: 10 }}>
                <StCloseButton text={t("Close")} onClick={() => props.setManuPrivacyPolicyModalShow(false)} />
              </div>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "80vh", overflow: "auto" }}>
                    <div dangerouslySetInnerHTML={{ __html: props.PrivacyPolicyContent }} />
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <Button
                        className="confirm_button"
                        variant="primary"
                        type="button"
                        onClick={handleOKClick}
                    >{t('Ok')}</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PrivacyPolicyModal