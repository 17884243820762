import React from 'react'
import { useTranslation } from 'react-i18next';

export const Additives = (props) => {
  const { name, label, value } = props
  const { t, i18n } = useTranslation('common');


  return (
    <div>
      {/* <span className="allerg-adt-subtitle">{t('May Contains')}</span> */}
      <span className="allerg-adt-subtitle">{t(label)}</span>
      {value && value[0] && value.map((additives, index) => (
        <span className="st-badge blue-badge" key={index}>
          {t(additives)}
        </span>
      ))}
    </div>
  )
}
