import React, {useState} from 'react'
import ImageModal from '../ImageModal'
import defaultProdImage from 'src/assets/img/Dummy_Product_Pic.png';


const MediaImage = (props) => {
    const {value} = props

    const [imageModal, setImageModal] = useState({
        isShowModal: false,
        imageUrl: "",
      })
    
    
      const handleShowImageModal = (imageUrl) => {
        if (imageModal.isShowModal) {
          setImageModal({
            isShowModal: false,
            imageUrl: "",
          })
        } else if (imageUrl) {
          setImageModal({
            isShowModal: true,
            imageUrl: imageUrl,
          })
        }
      }
    
      function addDefaultSrc(ev) {
        ev.target.src = defaultProdImage
      }

    return (
        <>
            <ImageModal
                isShowModal={imageModal.isShowModal}
                imageUrl={imageModal.imageUrl}
                modalClose={() => handleShowImageModal()}
            />
            <div className="imageSliderSection__item" key={`spl${value}`}>
                <img onClick={() => handleShowImageModal(value)} onError={addDefaultSrc} src={value}  className="sliderImg" />
            </div>
        </>
    )
}

export default MediaImage