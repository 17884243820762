import React from 'react'

const BatchIds = (props) => {

  return (
    <div className="row mt-10 attrDiv">
            <div className="col-12">
                <span className="attributeTitle">{props.label} : {props?.indBatchIdValue?.type}</span>
                <span className="attributeValue">
                   {props?.indBatchIdValue?.id}
                </span>
            </div>
        </div>
  )
}

export default BatchIds