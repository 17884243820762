import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "src/components/header";
import { useTranslation } from 'react-i18next';

const SearchPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const [searchText, setSearchText] = useState("");

  const searchProduct = () => {
    navigate(`/${searchText}`)
  }
  const checkEnterClick = (event) => {
    if (event.keyCode === 13) {
      navigate(`/${searchText}`)
    }
  }

  return (
    <>
      <Header />
      <div className="searchPage">
        <div className="d-flex flex-column">
          <div className="searchPage__search">
            <input
              className="searchPage__search__input "
              onChange={(e) => setSearchText(e.target.value)}
              onKeyUp={(e) => checkEnterClick(e)}
              placeholder={t('Search placeholder')}
              value={searchText}
            />
            <button
              onClick={() => searchProduct()}
              className="btn btn-dark searchPage__search__button"
              disabled={searchText === ""}
            >
              {t('Search')}
            </button>
          </div>
          <div className="mt-3 searchPage__search">
              <span className="searchPage__search__bottomlabel">{t("SearchPageBottomLabel")}&nbsp;<a href="https://www.upids.io" target="_blank" rel="noopener noreferrer" className="searchPage__search__bottomlink">www.upids.io</a></span>

          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPage;
