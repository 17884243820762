/**
 * App Loader
 */
import React from "react";


const AppLoader = () => {



  return (
    <div className="loader">

      <div className="spinner-border text-primary box" style={{ width: "7.5rem", height: " 7.5rem" }} role="status">


      </div>
      <img
        style={{ height: '3rem', width: '3rem', position: 'absolute' }}
        className="image"
        src={
          require("../assets/img/loadingIcon.svg").default
        }
        alt=""
      />
    </div>
  );
};

export default AppLoader;
