import React from 'react';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function UpidsNotFoundModal(props) {

    const { t } = useTranslation('common');


    return (
        <>
            <Modal size="md" centered={true} backdrop="static" show={props.showModal} onHide={() => props.setShowUpidsNotFoundModal(false)} >
                <Modal.Header >
                    {/* <span className="close-text" onClick={goBack} style={{ marginLeft: 390 }}>
                        <MdClose style={{ marginBottom: 2, color: 'black' }} fontSize={24} fontWeight={'bold'} />
                        {t('Close')}
                    </span> */}
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                    <div className="row m-0 justify-content-center">
                        <div className="form-group col-12 m-2"  >
                            <label className="info">{t("No data found for upids id: ")} {props.upidsdata}</label>
                            <label className="info">{t("Instead showing master product data")}</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <Button
                        className="confirm_button"
                        variant="primary"
                        type="button"
                        onClick={() => props.setShowUpidsNotFoundModal(false)}
                    >{t('Ok')}</Button>
                    {/* <Button
                        className="confirm_button"
                        variant="primary"
                        type="button"
                        onClick={goBack}
                    >{t('No')}</Button> */}
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default UpidsNotFoundModal