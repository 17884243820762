/**
 * App Footer
 */
import React from "react";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('common');
  const currentYear = new Date().getFullYear()
  return (
    <div id="reportissue" className="footersection">
      {/* <span className="title">Report an issue</span> */}

      <div className="footer">
        <div className="d-flex justify-content-center">
          <img
            src={require("src/assets/img/secondthought.png")}
            alt=""
            className="secondthoughtlogo"
          />
        </div>
        <div className="d-flex justify-content-center">
          <span style={{ textAlign: "center" }}>
            © {currentYear} Second Thought Ltd. {t("Information presented on this page is originating from external source systems, Second Thought Ltd cannot guarantee the quality of the data but does best to monitor it.")}
          </span>
        </div>
        <div className="d-flex justify-content-center mt-20">
          <a className="policyLink" style={{ textAlign: "center" }} href="https://www.secondthought.fi/privacy-policy">
            {t("Privacy policy")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
