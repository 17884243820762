import React, { useState, } from "react";
import { useSelector } from "react-redux";
import "src/App.css";
import { useTranslation } from 'react-i18next';
import { localStorageGet, Keys } from 'src/helper/localStorageHelper';
import _ from "lodash";
import LinkedUpidsData from "./LinkedUpidsData";
import D3TreeModal from "./d3Tree/D3TreeModal";


import D3Tree from "./d3Tree/D3Tree";

function LinkedUpids(props) {

     
    const { t, i18n } = useTranslation('common');
    const _localLanguage = localStorageGet(Keys.I18LANG_CODE);
    const { productData, productUpidsData, allLinkedUpidsIdData } = useSelector((state) => state.productSlice)

    const [isShowModal, setIsShowModal] = useState(false)

    const handleShowD3TreeModal = () => {
        setIsShowModal(!isShowModal)
    }



    return (
        <>
            <D3TreeModal
                isShowModal={isShowModal}
                allLinkedUpidsIdData={allLinkedUpidsIdData}
                modalClose={() => handleShowD3TreeModal()}
            />

            {_.get(props.value, 'linkedUpids', []).map((o, index) => (
                <LinkedUpidsData locale={_localLanguage} key={index} linkUpids={o} />
            ))
            }

            {!!allLinkedUpidsIdData &&
                <>
                    <section className="linked-section"  >

                        <div className="row mt-10 linkedProduct pt-3">
                            <div className="col-12">
                                <span className="attributeTitle">{t('Full chain of linked products')}</span>
                                <span className="attributeValue">
                                </span>
                            </div>
                        </div>

                        <div className="view-more-text-container">
                            <a style={{ cursor: 'pointer', color: 'blue' }} onClick={handleShowD3TreeModal}>{t("View graph")}</a>
                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default LinkedUpids