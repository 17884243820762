import React from 'react'
import { Helmet } from "react-helmet-async";



const SeoHelmet = (props) => {
    const { title, description, imageUrl, name, type } = props


    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            { /* End standard metadata tags */}


            { /* Facebook tags */}
            <meta property="og:type" content={"website"} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />
            { /* End Facebook tags */}


            { /* Twitter tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
            { /* End Twitter tags */}
        </Helmet>
    )
}

export default SeoHelmet