import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
import * as _ from 'lodash'
import { FOOD_BEWERAGE } from 'src/helper/constants/constants';
import { getProductSchemaBaseOnProductType, getProduct_flatten_TabStructured_Schema } from 'src/helper/helpers';

export const retriveProductDataWithUPIDSAction = createAsyncThunk(
  "product/retriveProductDataWithUPIDSAction",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().get(`/upids/product/public/upidsId/${arg}`); // arg = UPIDS
      const responseData = response.data
      if (responseData.linkedUpids && responseData.linkedUpids.length > 0) {
        await thunkApi.dispatch(getAllLinkedUpidsIdData(responseData.upidsId))
      }
      return responseData;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const retriveProductDataWithGTINAction = createAsyncThunk(
  "product/retriveProductDataWithGTINAction",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().get(`upids/masterdata/products/public/gtin/${arg}`); // arg = GTIN
      const responseData = response.data
      const productType = _.get(responseData, 'productType', FOOD_BEWERAGE)
      await thunkApi.dispatch(getProductTemplateSchemaAction({ productType: productType }))
      if (responseData.externalSources && 'enabled' in responseData.externalSources[0]) {
        if (_.get(responseData, 'externalSources[0].enabled')) {
          await thunkApi.dispatch(getProductDataFromExternalSource(responseData))
        }
      } else {
        if (responseData.externalSources && responseData.externalSources[0].name == "off") {
          await thunkApi.dispatch(getProductDataFromExternalSource(responseData))
        }
      }

      await thunkApi.dispatch(getWarningsForProductById(arg))

      // postMessage for v1 mobile app
      window.parent.postMessage(responseData, '*');

      // postMessage for v2 mobile app
      try {
        window.mobileapp.postMessage(JSON.stringify(responseData));
      } catch (error) {
      }

      return responseData
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);



export const retriveLinkedProductDataWithUPIDSAction = createAsyncThunk(
  "product/retriveLinkedProductDataWithUPIDSAction",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().get(`/upids/product/public/upidsId/${arg}`); // arg = UPIDS
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const retriveLinkedProductDataWithGTINAction = createAsyncThunk(
  "product/retriveLinkedProductDataWithGTINAction",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().get(`upids/masterdata/products/public/gtin/${arg}`); // arg = GTIN
      const responseData = response.data
      // if(_.get(responseData, 'externalSources[0].name')=="off"){
      //   await thunkApi.dispatch(getProductDataFromExternalSource(responseData)).unwrap()
      // }

      // postMessage for v1 mobile app
      window.parent.postMessage(responseData, '*');

      // postMessage for v2 mobile app
      try {
        window.mobileapp.postMessage(JSON.stringify(responseData));
      } catch (error) {
      }

      return responseData
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getProductDataFromExternalSource = createAsyncThunk(
  "product/getProductDataFromExternalSource",
  async (arg, thunkApi) => {
    try {
      let responseJson;
      responseJson = await getAxios().get(`https://world.openfoodfacts.org/api/v0/product/${arg.gtin}.json`); // arg = GTIN
      return { externalResponseProductJson: responseJson.data, responseData: arg }
    } catch (error) {
      return thunkApi.rejectWithValue([], { data: error.response.data })
    }
  }
);



/** get product template */
export const getProductTemplateSchemaAction = createAsyncThunk(
  "product/getProductTemplateSchemaAction",
  async (arg, thunkApi) => {
    try {
      const { productType = FOOD_BEWERAGE } = arg
      const response = await getAxios().get(
        `services/product/template/filter/use?name=${productType}`
      );
      const productSchema = response.data.template;
      // const productSchema = getProductSchemaBaseOnProductType(productType);
      const { productFlattenSchema, productTabStructuredSchema, upidsIoProductTabStructuredSchema } = getProduct_flatten_TabStructured_Schema(productSchema)
      const responseData = { productSchema, productFlattenSchema, productTabStructuredSchema, upidsIoProductTabStructuredSchema, productType }
      return responseData;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const getScanCountAction = createAsyncThunk(
  "product/getScanCountAction",
  async (arg, thunkApi) => {
    const { UPIDS, businessId } = arg
    try {
      const params = new URLSearchParams(window.location.search);
      const isMobileDevice = params.get("mobile");

      const response = await getAxios().get(`/reporting/clickstream/add/?eventName=scan&upidsCode=${UPIDS}&env=production&businessId=${businessId}${isMobileDevice === "1" ? "&mobile=1" : ""}`);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const getDataApiCallFailedAction = createAsyncThunk(
  "product/getDataApiCallFailedAction",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().get(`/reporting/clickstream/add/?eventName=error&upidsCode=${arg}&env=production`); // arg = UPIDS
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const getAllergensAction = createAsyncThunk(
  "product/getAllergensAction",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().get(`/upids/metadata/allergens?${arg}`); // arg = qString
      const responseData = response.data
      return responseData;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const sendFeedbackAction = createAsyncThunk(
  "product/sendFeedbackAction",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().post(`/upids/feedback/add`, arg, {
        // headers: {
        //   company_apikey: "-7762-49e8-8b64-5afbbdef714af6cb1167",
        // }
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const getAllLinkedUpidsIdData = createAsyncThunk(
  "product/getLinkedUpidsIdData",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().get(`/upids/get-linked-upids?mode=tree_graph&upidsId=${arg}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue([], { data: error.response.data });
    }
  }
)

export const getWarningsForProductById = createAsyncThunk(
  "product/getWarningsForProduct",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().get(`upids/warning/all?productIdentifier=${arg}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue([], { data: error.response.data });
    }
  }
)

export const getNutritionalClaimsLocale = createAsyncThunk(
  "product/getNutritionalClaimsLocale",
  async (arg, thunkApi) => {
    try {
      const response = await getAxios().get(`/upids/metadata/nutritional${arg}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue([], { data: error.response.data });
    }
  }
)

export const getManufacturerPrivacyPolicyContent = createAsyncThunk(
  "product/getManufacturerPrivacyPolicyContent",
  async (url, thunkApi) => {
    try {
      const response = await getAxios().get(url);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue([], { data: error.response.data });
    }
  }
)


export const isNutritionalDataShownFlag = createAction(
  'product/isNutritionalDataShownFlag',
  (model) => {
      return { payload: model }
  }
)



/** Get all certifications data */
export const getAllCertificationsData = createAsyncThunk(
  "certifications/getAllCertificationsData",
  async (arg, thunkApi) => {
      try {
          const response = await getAxios().get(
              `upids/metadata/certifications/all`
          );
          return response.data
      } catch (err) {
          return thunkApi.rejectWithValue(err);
      }
  }
)
