import React from "react";
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from "./StCloseButton";
import { useTranslation } from "react-i18next";
import CardPayment from "./CardPayment";

function PaymentModal(props) {
  const { productData, isShowModal, closeModal,directCommerceData } = props;
  const { t } = useTranslation("common");

  const handleClose = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        size="md"
        centered={true}
        backdrop="static"
        show={isShowModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <div style={{ position: "absolute", right: 10 }}>
            <StCloseButton
              text={t("Close")}
              onClick={() => handleClose()}
            ></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: "normal" }}>
          <CardPayment productData={productData} handleClose={handleClose} directCommerceData={directCommerceData} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PaymentModal;
